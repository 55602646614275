/* eslint-disable */
import { Reader, Writer } from "protobufjs/minimal";
import { Timestamp } from "./google/protobuf/timestamp";
import { Duration } from "./google/protobuf/duration";

export const protobufPackage = "shopaholic.service.v1";

/**
 * Shopaholic is the online marketplace for Bayfield Brewing Co.
 * The proto defintions define the interface for client/server
 * interaction.
 */

/** OrderRequest provides a request to purchase an order. */
export interface OrderRequest {
  /** The type of payment selected. */
  paymentType: OrderRequest_PaymentType;
  conveyanceType: OrderRequest_ConveyanceType;
  /** An optional card token returned by the payment processor. */
  card: string;
  /** An optional note supplied by the customer. */
  message: string;
  /** An optional amount applied to the bill as a tip. The */
  gratuity: string;
  /** Information about the customer. */
  customer: OrderRequest_Customer | undefined;
  /** The customer's shipping address. */
  shippingAddress: OrderRequest_Address | undefined;
  /** A collection of items selected for purchase. */
  items: OrderRequest_Item[];
  /** The time when the order should be fulfilled. */
  fulfillment: Date | undefined;
  /** The ID of the event that provided the fulfillment time. */
  eventID: string;
}

export enum OrderRequest_PaymentType {
  PAYMENT_TYPE_NONE = 0,
  PAYMENT_TYPE_OFFLINE = 1,
  PAYMENT_TYPE_CARD = 2,
  UNRECOGNIZED = -1,
}

export function orderRequest_PaymentTypeFromJSON(
  object: any
): OrderRequest_PaymentType {
  switch (object) {
    case 0:
    case "PAYMENT_TYPE_NONE":
      return OrderRequest_PaymentType.PAYMENT_TYPE_NONE;
    case 1:
    case "PAYMENT_TYPE_OFFLINE":
      return OrderRequest_PaymentType.PAYMENT_TYPE_OFFLINE;
    case 2:
    case "PAYMENT_TYPE_CARD":
      return OrderRequest_PaymentType.PAYMENT_TYPE_CARD;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OrderRequest_PaymentType.UNRECOGNIZED;
  }
}

export function orderRequest_PaymentTypeToJSON(
  object: OrderRequest_PaymentType
): string {
  switch (object) {
    case OrderRequest_PaymentType.PAYMENT_TYPE_NONE:
      return "PAYMENT_TYPE_NONE";
    case OrderRequest_PaymentType.PAYMENT_TYPE_OFFLINE:
      return "PAYMENT_TYPE_OFFLINE";
    case OrderRequest_PaymentType.PAYMENT_TYPE_CARD:
      return "PAYMENT_TYPE_CARD";
    default:
      return "UNKNOWN";
  }
}

export enum OrderRequest_ConveyanceType {
  CONVEYANCE_TYPE_NONE = 0,
  CONVEYANCE_TYPE_PICKUP = 1,
  CONVEYANCE_TYPE_SHIP = 2,
  CONVEYANCE_TYPE_LOCAL_DELIVERY = 3,
  CONVEYANCE_TYPE_DINE_IN = 4,
  UNRECOGNIZED = -1,
}

export function orderRequest_ConveyanceTypeFromJSON(
  object: any
): OrderRequest_ConveyanceType {
  switch (object) {
    case 0:
    case "CONVEYANCE_TYPE_NONE":
      return OrderRequest_ConveyanceType.CONVEYANCE_TYPE_NONE;
    case 1:
    case "CONVEYANCE_TYPE_PICKUP":
      return OrderRequest_ConveyanceType.CONVEYANCE_TYPE_PICKUP;
    case 2:
    case "CONVEYANCE_TYPE_SHIP":
      return OrderRequest_ConveyanceType.CONVEYANCE_TYPE_SHIP;
    case 3:
    case "CONVEYANCE_TYPE_LOCAL_DELIVERY":
      return OrderRequest_ConveyanceType.CONVEYANCE_TYPE_LOCAL_DELIVERY;
    case 4:
    case "CONVEYANCE_TYPE_DINE_IN":
      return OrderRequest_ConveyanceType.CONVEYANCE_TYPE_DINE_IN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OrderRequest_ConveyanceType.UNRECOGNIZED;
  }
}

export function orderRequest_ConveyanceTypeToJSON(
  object: OrderRequest_ConveyanceType
): string {
  switch (object) {
    case OrderRequest_ConveyanceType.CONVEYANCE_TYPE_NONE:
      return "CONVEYANCE_TYPE_NONE";
    case OrderRequest_ConveyanceType.CONVEYANCE_TYPE_PICKUP:
      return "CONVEYANCE_TYPE_PICKUP";
    case OrderRequest_ConveyanceType.CONVEYANCE_TYPE_SHIP:
      return "CONVEYANCE_TYPE_SHIP";
    case OrderRequest_ConveyanceType.CONVEYANCE_TYPE_LOCAL_DELIVERY:
      return "CONVEYANCE_TYPE_LOCAL_DELIVERY";
    case OrderRequest_ConveyanceType.CONVEYANCE_TYPE_DINE_IN:
      return "CONVEYANCE_TYPE_DINE_IN";
    default:
      return "UNKNOWN";
  }
}

export interface OrderRequest_Customer {
  /** The first name of the customer. */
  firstName: string;
  /** The last name of the customer. */
  lastName: string;
  /** The customer's email address. */
  email: string;
  /** The customer's phone number. */
  phone: string;
}

export interface OrderRequest_Address {
  street1: string;
  street2: string;
  city: string;
  postalCode: string;
}

export interface OrderRequest_Item {
  /** The product token. */
  token: string;
  /** The quanity of a product selected for purchase. */
  quantity: number;
  /** Additional instructions for the item. */
  instructions: string;
  /** Optional product options. */
  option: OrderRequest_Item_Option | undefined;
}

export interface OrderRequest_Item_Option {
  sku: string;
}

/** OrderResponse is provded in response to placing an order. */
export interface OrderResponse {
  error: Error | undefined;
  id: string;
}

export interface Error {
  validationErrors: string[];
}

export interface EventCreateRequest {
  /** The ID given from previous create request responses. Not */
  id: string;
  /** required for first request. */
  time: Date | undefined;
  duration: Duration | undefined;
  units: number;
  /** The base URL of the requesting site. */
  origin: string;
  /** The product tokens for the event. Not necessary, but can be used to */
  productTokens: string[];
}

export interface EventCreateResponse {
  id: string;
  time: Date | undefined;
  /** TODO: Consider making this an enum. */
  reason: string;
}

export interface EventCommitRequest {
  id: string;
}

export interface EventCommitResponse {}

export interface EventCancelRequest {
  id: string;
}

export interface EventCancelResponse {}

export interface BusinessHoursListRequest {
  /** The base URL of the requesting site. */
  origin: string;
}

export interface BusinessHoursListResponse {
  hours: BusinessHoursListResponse_Hours[];
}

export interface BusinessHoursListResponse_Hours {
  weekday: number;
  open: BusinessHoursListResponse_Hours_Time | undefined;
  close: BusinessHoursListResponse_Hours_Time | undefined;
  validFrom: Date | undefined;
  validThrough: Date | undefined;
}

export interface BusinessHoursListResponse_Hours_Time {
  hour: number;
  minute: number;
}

export interface FindProductTokenRequest {
  /** The SKU of the desired product. */
  sku: string;
  /** The base URL of the requesting site. */
  origin: string;
}

export interface FindProductTokenResponse {
  token: string;
}

const baseOrderRequest: object = {
  paymentType: 0,
  conveyanceType: 0,
  card: "",
  message: "",
  gratuity: "",
  eventID: "",
};

export const OrderRequest = {
  encode(message: OrderRequest, writer: Writer = Writer.create()): Writer {
    if (message.paymentType !== 0) {
      writer.uint32(24).int32(message.paymentType);
    }
    if (message.conveyanceType !== 0) {
      writer.uint32(56).int32(message.conveyanceType);
    }
    if (message.card !== "") {
      writer.uint32(10).string(message.card);
    }
    if (message.message !== "") {
      writer.uint32(66).string(message.message);
    }
    if (message.gratuity !== "") {
      writer.uint32(90).string(message.gratuity);
    }
    if (message.customer !== undefined) {
      OrderRequest_Customer.encode(
        message.customer,
        writer.uint32(34).fork()
      ).ldelim();
    }
    if (message.shippingAddress !== undefined) {
      OrderRequest_Address.encode(
        message.shippingAddress,
        writer.uint32(50).fork()
      ).ldelim();
    }
    for (const v of message.items) {
      OrderRequest_Item.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.fulfillment !== undefined) {
      Timestamp.encode(
        toTimestamp(message.fulfillment),
        writer.uint32(74).fork()
      ).ldelim();
    }
    if (message.eventID !== "") {
      writer.uint32(82).string(message.eventID);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): OrderRequest {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseOrderRequest } as OrderRequest;
    message.items = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          message.paymentType = reader.int32() as any;
          break;
        case 7:
          message.conveyanceType = reader.int32() as any;
          break;
        case 1:
          message.card = reader.string();
          break;
        case 8:
          message.message = reader.string();
          break;
        case 11:
          message.gratuity = reader.string();
          break;
        case 4:
          message.customer = OrderRequest_Customer.decode(
            reader,
            reader.uint32()
          );
          break;
        case 6:
          message.shippingAddress = OrderRequest_Address.decode(
            reader,
            reader.uint32()
          );
          break;
        case 2:
          message.items.push(OrderRequest_Item.decode(reader, reader.uint32()));
          break;
        case 9:
          message.fulfillment = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          );
          break;
        case 10:
          message.eventID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OrderRequest {
    const message = { ...baseOrderRequest } as OrderRequest;
    message.items = [];
    if (object.paymentType !== undefined && object.paymentType !== null) {
      message.paymentType = orderRequest_PaymentTypeFromJSON(
        object.paymentType
      );
    } else {
      message.paymentType = 0;
    }
    if (object.conveyanceType !== undefined && object.conveyanceType !== null) {
      message.conveyanceType = orderRequest_ConveyanceTypeFromJSON(
        object.conveyanceType
      );
    } else {
      message.conveyanceType = 0;
    }
    if (object.card !== undefined && object.card !== null) {
      message.card = String(object.card);
    } else {
      message.card = "";
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = String(object.message);
    } else {
      message.message = "";
    }
    if (object.gratuity !== undefined && object.gratuity !== null) {
      message.gratuity = String(object.gratuity);
    } else {
      message.gratuity = "";
    }
    if (object.customer !== undefined && object.customer !== null) {
      message.customer = OrderRequest_Customer.fromJSON(object.customer);
    } else {
      message.customer = undefined;
    }
    if (
      object.shippingAddress !== undefined &&
      object.shippingAddress !== null
    ) {
      message.shippingAddress = OrderRequest_Address.fromJSON(
        object.shippingAddress
      );
    } else {
      message.shippingAddress = undefined;
    }
    if (object.items !== undefined && object.items !== null) {
      for (const e of object.items) {
        message.items.push(OrderRequest_Item.fromJSON(e));
      }
    }
    if (object.fulfillment !== undefined && object.fulfillment !== null) {
      message.fulfillment = fromJsonTimestamp(object.fulfillment);
    } else {
      message.fulfillment = undefined;
    }
    if (object.eventID !== undefined && object.eventID !== null) {
      message.eventID = String(object.eventID);
    } else {
      message.eventID = "";
    }
    return message;
  },

  toJSON(message: OrderRequest): unknown {
    const obj: any = {};
    message.paymentType !== undefined &&
      (obj.paymentType = orderRequest_PaymentTypeToJSON(message.paymentType));
    message.conveyanceType !== undefined &&
      (obj.conveyanceType = orderRequest_ConveyanceTypeToJSON(
        message.conveyanceType
      ));
    message.card !== undefined && (obj.card = message.card);
    message.message !== undefined && (obj.message = message.message);
    message.gratuity !== undefined && (obj.gratuity = message.gratuity);
    message.customer !== undefined &&
      (obj.customer = message.customer
        ? OrderRequest_Customer.toJSON(message.customer)
        : undefined);
    message.shippingAddress !== undefined &&
      (obj.shippingAddress = message.shippingAddress
        ? OrderRequest_Address.toJSON(message.shippingAddress)
        : undefined);
    if (message.items) {
      obj.items = message.items.map((e) =>
        e ? OrderRequest_Item.toJSON(e) : undefined
      );
    } else {
      obj.items = [];
    }
    message.fulfillment !== undefined &&
      (obj.fulfillment =
        message.fulfillment !== undefined
          ? message.fulfillment.toISOString()
          : null);
    message.eventID !== undefined && (obj.eventID = message.eventID);
    return obj;
  },

  fromPartial(object: DeepPartial<OrderRequest>): OrderRequest {
    const message = { ...baseOrderRequest } as OrderRequest;
    message.items = [];
    if (object.paymentType !== undefined && object.paymentType !== null) {
      message.paymentType = object.paymentType;
    } else {
      message.paymentType = 0;
    }
    if (object.conveyanceType !== undefined && object.conveyanceType !== null) {
      message.conveyanceType = object.conveyanceType;
    } else {
      message.conveyanceType = 0;
    }
    if (object.card !== undefined && object.card !== null) {
      message.card = object.card;
    } else {
      message.card = "";
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = object.message;
    } else {
      message.message = "";
    }
    if (object.gratuity !== undefined && object.gratuity !== null) {
      message.gratuity = object.gratuity;
    } else {
      message.gratuity = "";
    }
    if (object.customer !== undefined && object.customer !== null) {
      message.customer = OrderRequest_Customer.fromPartial(object.customer);
    } else {
      message.customer = undefined;
    }
    if (
      object.shippingAddress !== undefined &&
      object.shippingAddress !== null
    ) {
      message.shippingAddress = OrderRequest_Address.fromPartial(
        object.shippingAddress
      );
    } else {
      message.shippingAddress = undefined;
    }
    if (object.items !== undefined && object.items !== null) {
      for (const e of object.items) {
        message.items.push(OrderRequest_Item.fromPartial(e));
      }
    }
    if (object.fulfillment !== undefined && object.fulfillment !== null) {
      message.fulfillment = object.fulfillment;
    } else {
      message.fulfillment = undefined;
    }
    if (object.eventID !== undefined && object.eventID !== null) {
      message.eventID = object.eventID;
    } else {
      message.eventID = "";
    }
    return message;
  },
};

const baseOrderRequest_Customer: object = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
};

export const OrderRequest_Customer = {
  encode(
    message: OrderRequest_Customer,
    writer: Writer = Writer.create()
  ): Writer {
    if (message.firstName !== "") {
      writer.uint32(10).string(message.firstName);
    }
    if (message.lastName !== "") {
      writer.uint32(18).string(message.lastName);
    }
    if (message.email !== "") {
      writer.uint32(26).string(message.email);
    }
    if (message.phone !== "") {
      writer.uint32(34).string(message.phone);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): OrderRequest_Customer {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseOrderRequest_Customer } as OrderRequest_Customer;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.firstName = reader.string();
          break;
        case 2:
          message.lastName = reader.string();
          break;
        case 3:
          message.email = reader.string();
          break;
        case 4:
          message.phone = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OrderRequest_Customer {
    const message = { ...baseOrderRequest_Customer } as OrderRequest_Customer;
    if (object.firstName !== undefined && object.firstName !== null) {
      message.firstName = String(object.firstName);
    } else {
      message.firstName = "";
    }
    if (object.lastName !== undefined && object.lastName !== null) {
      message.lastName = String(object.lastName);
    } else {
      message.lastName = "";
    }
    if (object.email !== undefined && object.email !== null) {
      message.email = String(object.email);
    } else {
      message.email = "";
    }
    if (object.phone !== undefined && object.phone !== null) {
      message.phone = String(object.phone);
    } else {
      message.phone = "";
    }
    return message;
  },

  toJSON(message: OrderRequest_Customer): unknown {
    const obj: any = {};
    message.firstName !== undefined && (obj.firstName = message.firstName);
    message.lastName !== undefined && (obj.lastName = message.lastName);
    message.email !== undefined && (obj.email = message.email);
    message.phone !== undefined && (obj.phone = message.phone);
    return obj;
  },

  fromPartial(
    object: DeepPartial<OrderRequest_Customer>
  ): OrderRequest_Customer {
    const message = { ...baseOrderRequest_Customer } as OrderRequest_Customer;
    if (object.firstName !== undefined && object.firstName !== null) {
      message.firstName = object.firstName;
    } else {
      message.firstName = "";
    }
    if (object.lastName !== undefined && object.lastName !== null) {
      message.lastName = object.lastName;
    } else {
      message.lastName = "";
    }
    if (object.email !== undefined && object.email !== null) {
      message.email = object.email;
    } else {
      message.email = "";
    }
    if (object.phone !== undefined && object.phone !== null) {
      message.phone = object.phone;
    } else {
      message.phone = "";
    }
    return message;
  },
};

const baseOrderRequest_Address: object = {
  street1: "",
  street2: "",
  city: "",
  postalCode: "",
};

export const OrderRequest_Address = {
  encode(
    message: OrderRequest_Address,
    writer: Writer = Writer.create()
  ): Writer {
    if (message.street1 !== "") {
      writer.uint32(10).string(message.street1);
    }
    if (message.street2 !== "") {
      writer.uint32(18).string(message.street2);
    }
    if (message.city !== "") {
      writer.uint32(26).string(message.city);
    }
    if (message.postalCode !== "") {
      writer.uint32(34).string(message.postalCode);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): OrderRequest_Address {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseOrderRequest_Address } as OrderRequest_Address;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.street1 = reader.string();
          break;
        case 2:
          message.street2 = reader.string();
          break;
        case 3:
          message.city = reader.string();
          break;
        case 4:
          message.postalCode = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OrderRequest_Address {
    const message = { ...baseOrderRequest_Address } as OrderRequest_Address;
    if (object.street1 !== undefined && object.street1 !== null) {
      message.street1 = String(object.street1);
    } else {
      message.street1 = "";
    }
    if (object.street2 !== undefined && object.street2 !== null) {
      message.street2 = String(object.street2);
    } else {
      message.street2 = "";
    }
    if (object.city !== undefined && object.city !== null) {
      message.city = String(object.city);
    } else {
      message.city = "";
    }
    if (object.postalCode !== undefined && object.postalCode !== null) {
      message.postalCode = String(object.postalCode);
    } else {
      message.postalCode = "";
    }
    return message;
  },

  toJSON(message: OrderRequest_Address): unknown {
    const obj: any = {};
    message.street1 !== undefined && (obj.street1 = message.street1);
    message.street2 !== undefined && (obj.street2 = message.street2);
    message.city !== undefined && (obj.city = message.city);
    message.postalCode !== undefined && (obj.postalCode = message.postalCode);
    return obj;
  },

  fromPartial(object: DeepPartial<OrderRequest_Address>): OrderRequest_Address {
    const message = { ...baseOrderRequest_Address } as OrderRequest_Address;
    if (object.street1 !== undefined && object.street1 !== null) {
      message.street1 = object.street1;
    } else {
      message.street1 = "";
    }
    if (object.street2 !== undefined && object.street2 !== null) {
      message.street2 = object.street2;
    } else {
      message.street2 = "";
    }
    if (object.city !== undefined && object.city !== null) {
      message.city = object.city;
    } else {
      message.city = "";
    }
    if (object.postalCode !== undefined && object.postalCode !== null) {
      message.postalCode = object.postalCode;
    } else {
      message.postalCode = "";
    }
    return message;
  },
};

const baseOrderRequest_Item: object = {
  token: "",
  quantity: 0,
  instructions: "",
};

export const OrderRequest_Item = {
  encode(message: OrderRequest_Item, writer: Writer = Writer.create()): Writer {
    if (message.token !== "") {
      writer.uint32(10).string(message.token);
    }
    if (message.quantity !== 0) {
      writer.uint32(16).int32(message.quantity);
    }
    if (message.instructions !== "") {
      writer.uint32(26).string(message.instructions);
    }
    if (message.option !== undefined) {
      OrderRequest_Item_Option.encode(
        message.option,
        writer.uint32(34).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): OrderRequest_Item {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseOrderRequest_Item } as OrderRequest_Item;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.token = reader.string();
          break;
        case 2:
          message.quantity = reader.int32();
          break;
        case 3:
          message.instructions = reader.string();
          break;
        case 4:
          message.option = OrderRequest_Item_Option.decode(
            reader,
            reader.uint32()
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OrderRequest_Item {
    const message = { ...baseOrderRequest_Item } as OrderRequest_Item;
    if (object.token !== undefined && object.token !== null) {
      message.token = String(object.token);
    } else {
      message.token = "";
    }
    if (object.quantity !== undefined && object.quantity !== null) {
      message.quantity = Number(object.quantity);
    } else {
      message.quantity = 0;
    }
    if (object.instructions !== undefined && object.instructions !== null) {
      message.instructions = String(object.instructions);
    } else {
      message.instructions = "";
    }
    if (object.option !== undefined && object.option !== null) {
      message.option = OrderRequest_Item_Option.fromJSON(object.option);
    } else {
      message.option = undefined;
    }
    return message;
  },

  toJSON(message: OrderRequest_Item): unknown {
    const obj: any = {};
    message.token !== undefined && (obj.token = message.token);
    message.quantity !== undefined && (obj.quantity = message.quantity);
    message.instructions !== undefined &&
      (obj.instructions = message.instructions);
    message.option !== undefined &&
      (obj.option = message.option
        ? OrderRequest_Item_Option.toJSON(message.option)
        : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<OrderRequest_Item>): OrderRequest_Item {
    const message = { ...baseOrderRequest_Item } as OrderRequest_Item;
    if (object.token !== undefined && object.token !== null) {
      message.token = object.token;
    } else {
      message.token = "";
    }
    if (object.quantity !== undefined && object.quantity !== null) {
      message.quantity = object.quantity;
    } else {
      message.quantity = 0;
    }
    if (object.instructions !== undefined && object.instructions !== null) {
      message.instructions = object.instructions;
    } else {
      message.instructions = "";
    }
    if (object.option !== undefined && object.option !== null) {
      message.option = OrderRequest_Item_Option.fromPartial(object.option);
    } else {
      message.option = undefined;
    }
    return message;
  },
};

const baseOrderRequest_Item_Option: object = { sku: "" };

export const OrderRequest_Item_Option = {
  encode(
    message: OrderRequest_Item_Option,
    writer: Writer = Writer.create()
  ): Writer {
    if (message.sku !== "") {
      writer.uint32(10).string(message.sku);
    }
    return writer;
  },

  decode(
    input: Reader | Uint8Array,
    length?: number
  ): OrderRequest_Item_Option {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseOrderRequest_Item_Option,
    } as OrderRequest_Item_Option;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.sku = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OrderRequest_Item_Option {
    const message = {
      ...baseOrderRequest_Item_Option,
    } as OrderRequest_Item_Option;
    if (object.sku !== undefined && object.sku !== null) {
      message.sku = String(object.sku);
    } else {
      message.sku = "";
    }
    return message;
  },

  toJSON(message: OrderRequest_Item_Option): unknown {
    const obj: any = {};
    message.sku !== undefined && (obj.sku = message.sku);
    return obj;
  },

  fromPartial(
    object: DeepPartial<OrderRequest_Item_Option>
  ): OrderRequest_Item_Option {
    const message = {
      ...baseOrderRequest_Item_Option,
    } as OrderRequest_Item_Option;
    if (object.sku !== undefined && object.sku !== null) {
      message.sku = object.sku;
    } else {
      message.sku = "";
    }
    return message;
  },
};

const baseOrderResponse: object = { id: "" };

export const OrderResponse = {
  encode(message: OrderResponse, writer: Writer = Writer.create()): Writer {
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(10).fork()).ldelim();
    }
    if (message.id !== "") {
      writer.uint32(18).string(message.id);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): OrderResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseOrderResponse } as OrderResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.error = Error.decode(reader, reader.uint32());
          break;
        case 2:
          message.id = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OrderResponse {
    const message = { ...baseOrderResponse } as OrderResponse;
    if (object.error !== undefined && object.error !== null) {
      message.error = Error.fromJSON(object.error);
    } else {
      message.error = undefined;
    }
    if (object.id !== undefined && object.id !== null) {
      message.id = String(object.id);
    } else {
      message.id = "";
    }
    return message;
  },

  toJSON(message: OrderResponse): unknown {
    const obj: any = {};
    message.error !== undefined &&
      (obj.error = message.error ? Error.toJSON(message.error) : undefined);
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  fromPartial(object: DeepPartial<OrderResponse>): OrderResponse {
    const message = { ...baseOrderResponse } as OrderResponse;
    if (object.error !== undefined && object.error !== null) {
      message.error = Error.fromPartial(object.error);
    } else {
      message.error = undefined;
    }
    if (object.id !== undefined && object.id !== null) {
      message.id = object.id;
    } else {
      message.id = "";
    }
    return message;
  },
};

const baseError: object = { validationErrors: "" };

export const Error = {
  encode(message: Error, writer: Writer = Writer.create()): Writer {
    for (const v of message.validationErrors) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): Error {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseError } as Error;
    message.validationErrors = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.validationErrors.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Error {
    const message = { ...baseError } as Error;
    message.validationErrors = [];
    if (
      object.validationErrors !== undefined &&
      object.validationErrors !== null
    ) {
      for (const e of object.validationErrors) {
        message.validationErrors.push(String(e));
      }
    }
    return message;
  },

  toJSON(message: Error): unknown {
    const obj: any = {};
    if (message.validationErrors) {
      obj.validationErrors = message.validationErrors.map((e) => e);
    } else {
      obj.validationErrors = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<Error>): Error {
    const message = { ...baseError } as Error;
    message.validationErrors = [];
    if (
      object.validationErrors !== undefined &&
      object.validationErrors !== null
    ) {
      for (const e of object.validationErrors) {
        message.validationErrors.push(e);
      }
    }
    return message;
  },
};

const baseEventCreateRequest: object = {
  id: "",
  units: 0,
  origin: "",
  productTokens: "",
};

export const EventCreateRequest = {
  encode(
    message: EventCreateRequest,
    writer: Writer = Writer.create()
  ): Writer {
    if (message.id !== "") {
      writer.uint32(34).string(message.id);
    }
    if (message.time !== undefined) {
      Timestamp.encode(
        toTimestamp(message.time),
        writer.uint32(10).fork()
      ).ldelim();
    }
    if (message.duration !== undefined) {
      Duration.encode(message.duration, writer.uint32(18).fork()).ldelim();
    }
    if (message.units !== 0) {
      writer.uint32(24).int32(message.units);
    }
    if (message.origin !== "") {
      writer.uint32(42).string(message.origin);
    }
    for (const v of message.productTokens) {
      writer.uint32(50).string(v!);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): EventCreateRequest {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseEventCreateRequest } as EventCreateRequest;
    message.productTokens = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 4:
          message.id = reader.string();
          break;
        case 1:
          message.time = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          );
          break;
        case 2:
          message.duration = Duration.decode(reader, reader.uint32());
          break;
        case 3:
          message.units = reader.int32();
          break;
        case 5:
          message.origin = reader.string();
          break;
        case 6:
          message.productTokens.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): EventCreateRequest {
    const message = { ...baseEventCreateRequest } as EventCreateRequest;
    message.productTokens = [];
    if (object.id !== undefined && object.id !== null) {
      message.id = String(object.id);
    } else {
      message.id = "";
    }
    if (object.time !== undefined && object.time !== null) {
      message.time = fromJsonTimestamp(object.time);
    } else {
      message.time = undefined;
    }
    if (object.duration !== undefined && object.duration !== null) {
      message.duration = Duration.fromJSON(object.duration);
    } else {
      message.duration = undefined;
    }
    if (object.units !== undefined && object.units !== null) {
      message.units = Number(object.units);
    } else {
      message.units = 0;
    }
    if (object.origin !== undefined && object.origin !== null) {
      message.origin = String(object.origin);
    } else {
      message.origin = "";
    }
    if (object.productTokens !== undefined && object.productTokens !== null) {
      for (const e of object.productTokens) {
        message.productTokens.push(String(e));
      }
    }
    return message;
  },

  toJSON(message: EventCreateRequest): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.time !== undefined &&
      (obj.time =
        message.time !== undefined ? message.time.toISOString() : null);
    message.duration !== undefined &&
      (obj.duration = message.duration
        ? Duration.toJSON(message.duration)
        : undefined);
    message.units !== undefined && (obj.units = message.units);
    message.origin !== undefined && (obj.origin = message.origin);
    if (message.productTokens) {
      obj.productTokens = message.productTokens.map((e) => e);
    } else {
      obj.productTokens = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<EventCreateRequest>): EventCreateRequest {
    const message = { ...baseEventCreateRequest } as EventCreateRequest;
    message.productTokens = [];
    if (object.id !== undefined && object.id !== null) {
      message.id = object.id;
    } else {
      message.id = "";
    }
    if (object.time !== undefined && object.time !== null) {
      message.time = object.time;
    } else {
      message.time = undefined;
    }
    if (object.duration !== undefined && object.duration !== null) {
      message.duration = Duration.fromPartial(object.duration);
    } else {
      message.duration = undefined;
    }
    if (object.units !== undefined && object.units !== null) {
      message.units = object.units;
    } else {
      message.units = 0;
    }
    if (object.origin !== undefined && object.origin !== null) {
      message.origin = object.origin;
    } else {
      message.origin = "";
    }
    if (object.productTokens !== undefined && object.productTokens !== null) {
      for (const e of object.productTokens) {
        message.productTokens.push(e);
      }
    }
    return message;
  },
};

const baseEventCreateResponse: object = { id: "", reason: "" };

export const EventCreateResponse = {
  encode(
    message: EventCreateResponse,
    writer: Writer = Writer.create()
  ): Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.time !== undefined) {
      Timestamp.encode(
        toTimestamp(message.time),
        writer.uint32(18).fork()
      ).ldelim();
    }
    if (message.reason !== "") {
      writer.uint32(26).string(message.reason);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): EventCreateResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseEventCreateResponse } as EventCreateResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.time = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          );
          break;
        case 3:
          message.reason = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): EventCreateResponse {
    const message = { ...baseEventCreateResponse } as EventCreateResponse;
    if (object.id !== undefined && object.id !== null) {
      message.id = String(object.id);
    } else {
      message.id = "";
    }
    if (object.time !== undefined && object.time !== null) {
      message.time = fromJsonTimestamp(object.time);
    } else {
      message.time = undefined;
    }
    if (object.reason !== undefined && object.reason !== null) {
      message.reason = String(object.reason);
    } else {
      message.reason = "";
    }
    return message;
  },

  toJSON(message: EventCreateResponse): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.time !== undefined &&
      (obj.time =
        message.time !== undefined ? message.time.toISOString() : null);
    message.reason !== undefined && (obj.reason = message.reason);
    return obj;
  },

  fromPartial(object: DeepPartial<EventCreateResponse>): EventCreateResponse {
    const message = { ...baseEventCreateResponse } as EventCreateResponse;
    if (object.id !== undefined && object.id !== null) {
      message.id = object.id;
    } else {
      message.id = "";
    }
    if (object.time !== undefined && object.time !== null) {
      message.time = object.time;
    } else {
      message.time = undefined;
    }
    if (object.reason !== undefined && object.reason !== null) {
      message.reason = object.reason;
    } else {
      message.reason = "";
    }
    return message;
  },
};

const baseEventCommitRequest: object = { id: "" };

export const EventCommitRequest = {
  encode(
    message: EventCommitRequest,
    writer: Writer = Writer.create()
  ): Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): EventCommitRequest {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseEventCommitRequest } as EventCommitRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): EventCommitRequest {
    const message = { ...baseEventCommitRequest } as EventCommitRequest;
    if (object.id !== undefined && object.id !== null) {
      message.id = String(object.id);
    } else {
      message.id = "";
    }
    return message;
  },

  toJSON(message: EventCommitRequest): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  fromPartial(object: DeepPartial<EventCommitRequest>): EventCommitRequest {
    const message = { ...baseEventCommitRequest } as EventCommitRequest;
    if (object.id !== undefined && object.id !== null) {
      message.id = object.id;
    } else {
      message.id = "";
    }
    return message;
  },
};

const baseEventCommitResponse: object = {};

export const EventCommitResponse = {
  encode(_: EventCommitResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): EventCommitResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseEventCommitResponse } as EventCommitResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): EventCommitResponse {
    const message = { ...baseEventCommitResponse } as EventCommitResponse;
    return message;
  },

  toJSON(_: EventCommitResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<EventCommitResponse>): EventCommitResponse {
    const message = { ...baseEventCommitResponse } as EventCommitResponse;
    return message;
  },
};

const baseEventCancelRequest: object = { id: "" };

export const EventCancelRequest = {
  encode(
    message: EventCancelRequest,
    writer: Writer = Writer.create()
  ): Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): EventCancelRequest {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseEventCancelRequest } as EventCancelRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): EventCancelRequest {
    const message = { ...baseEventCancelRequest } as EventCancelRequest;
    if (object.id !== undefined && object.id !== null) {
      message.id = String(object.id);
    } else {
      message.id = "";
    }
    return message;
  },

  toJSON(message: EventCancelRequest): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  fromPartial(object: DeepPartial<EventCancelRequest>): EventCancelRequest {
    const message = { ...baseEventCancelRequest } as EventCancelRequest;
    if (object.id !== undefined && object.id !== null) {
      message.id = object.id;
    } else {
      message.id = "";
    }
    return message;
  },
};

const baseEventCancelResponse: object = {};

export const EventCancelResponse = {
  encode(_: EventCancelResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): EventCancelResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseEventCancelResponse } as EventCancelResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): EventCancelResponse {
    const message = { ...baseEventCancelResponse } as EventCancelResponse;
    return message;
  },

  toJSON(_: EventCancelResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<EventCancelResponse>): EventCancelResponse {
    const message = { ...baseEventCancelResponse } as EventCancelResponse;
    return message;
  },
};

const baseBusinessHoursListRequest: object = { origin: "" };

export const BusinessHoursListRequest = {
  encode(
    message: BusinessHoursListRequest,
    writer: Writer = Writer.create()
  ): Writer {
    if (message.origin !== "") {
      writer.uint32(10).string(message.origin);
    }
    return writer;
  },

  decode(
    input: Reader | Uint8Array,
    length?: number
  ): BusinessHoursListRequest {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseBusinessHoursListRequest,
    } as BusinessHoursListRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.origin = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): BusinessHoursListRequest {
    const message = {
      ...baseBusinessHoursListRequest,
    } as BusinessHoursListRequest;
    if (object.origin !== undefined && object.origin !== null) {
      message.origin = String(object.origin);
    } else {
      message.origin = "";
    }
    return message;
  },

  toJSON(message: BusinessHoursListRequest): unknown {
    const obj: any = {};
    message.origin !== undefined && (obj.origin = message.origin);
    return obj;
  },

  fromPartial(
    object: DeepPartial<BusinessHoursListRequest>
  ): BusinessHoursListRequest {
    const message = {
      ...baseBusinessHoursListRequest,
    } as BusinessHoursListRequest;
    if (object.origin !== undefined && object.origin !== null) {
      message.origin = object.origin;
    } else {
      message.origin = "";
    }
    return message;
  },
};

const baseBusinessHoursListResponse: object = {};

export const BusinessHoursListResponse = {
  encode(
    message: BusinessHoursListResponse,
    writer: Writer = Writer.create()
  ): Writer {
    for (const v of message.hours) {
      BusinessHoursListResponse_Hours.encode(
        v!,
        writer.uint32(10).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: Reader | Uint8Array,
    length?: number
  ): BusinessHoursListResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseBusinessHoursListResponse,
    } as BusinessHoursListResponse;
    message.hours = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.hours.push(
            BusinessHoursListResponse_Hours.decode(reader, reader.uint32())
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): BusinessHoursListResponse {
    const message = {
      ...baseBusinessHoursListResponse,
    } as BusinessHoursListResponse;
    message.hours = [];
    if (object.hours !== undefined && object.hours !== null) {
      for (const e of object.hours) {
        message.hours.push(BusinessHoursListResponse_Hours.fromJSON(e));
      }
    }
    return message;
  },

  toJSON(message: BusinessHoursListResponse): unknown {
    const obj: any = {};
    if (message.hours) {
      obj.hours = message.hours.map((e) =>
        e ? BusinessHoursListResponse_Hours.toJSON(e) : undefined
      );
    } else {
      obj.hours = [];
    }
    return obj;
  },

  fromPartial(
    object: DeepPartial<BusinessHoursListResponse>
  ): BusinessHoursListResponse {
    const message = {
      ...baseBusinessHoursListResponse,
    } as BusinessHoursListResponse;
    message.hours = [];
    if (object.hours !== undefined && object.hours !== null) {
      for (const e of object.hours) {
        message.hours.push(BusinessHoursListResponse_Hours.fromPartial(e));
      }
    }
    return message;
  },
};

const baseBusinessHoursListResponse_Hours: object = { weekday: 0 };

export const BusinessHoursListResponse_Hours = {
  encode(
    message: BusinessHoursListResponse_Hours,
    writer: Writer = Writer.create()
  ): Writer {
    if (message.weekday !== 0) {
      writer.uint32(8).int32(message.weekday);
    }
    if (message.open !== undefined) {
      BusinessHoursListResponse_Hours_Time.encode(
        message.open,
        writer.uint32(18).fork()
      ).ldelim();
    }
    if (message.close !== undefined) {
      BusinessHoursListResponse_Hours_Time.encode(
        message.close,
        writer.uint32(26).fork()
      ).ldelim();
    }
    if (message.validFrom !== undefined) {
      Timestamp.encode(
        toTimestamp(message.validFrom),
        writer.uint32(34).fork()
      ).ldelim();
    }
    if (message.validThrough !== undefined) {
      Timestamp.encode(
        toTimestamp(message.validThrough),
        writer.uint32(42).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: Reader | Uint8Array,
    length?: number
  ): BusinessHoursListResponse_Hours {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseBusinessHoursListResponse_Hours,
    } as BusinessHoursListResponse_Hours;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.weekday = reader.int32();
          break;
        case 2:
          message.open = BusinessHoursListResponse_Hours_Time.decode(
            reader,
            reader.uint32()
          );
          break;
        case 3:
          message.close = BusinessHoursListResponse_Hours_Time.decode(
            reader,
            reader.uint32()
          );
          break;
        case 4:
          message.validFrom = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          );
          break;
        case 5:
          message.validThrough = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): BusinessHoursListResponse_Hours {
    const message = {
      ...baseBusinessHoursListResponse_Hours,
    } as BusinessHoursListResponse_Hours;
    if (object.weekday !== undefined && object.weekday !== null) {
      message.weekday = Number(object.weekday);
    } else {
      message.weekday = 0;
    }
    if (object.open !== undefined && object.open !== null) {
      message.open = BusinessHoursListResponse_Hours_Time.fromJSON(object.open);
    } else {
      message.open = undefined;
    }
    if (object.close !== undefined && object.close !== null) {
      message.close = BusinessHoursListResponse_Hours_Time.fromJSON(
        object.close
      );
    } else {
      message.close = undefined;
    }
    if (object.validFrom !== undefined && object.validFrom !== null) {
      message.validFrom = fromJsonTimestamp(object.validFrom);
    } else {
      message.validFrom = undefined;
    }
    if (object.validThrough !== undefined && object.validThrough !== null) {
      message.validThrough = fromJsonTimestamp(object.validThrough);
    } else {
      message.validThrough = undefined;
    }
    return message;
  },

  toJSON(message: BusinessHoursListResponse_Hours): unknown {
    const obj: any = {};
    message.weekday !== undefined && (obj.weekday = message.weekday);
    message.open !== undefined &&
      (obj.open = message.open
        ? BusinessHoursListResponse_Hours_Time.toJSON(message.open)
        : undefined);
    message.close !== undefined &&
      (obj.close = message.close
        ? BusinessHoursListResponse_Hours_Time.toJSON(message.close)
        : undefined);
    message.validFrom !== undefined &&
      (obj.validFrom =
        message.validFrom !== undefined
          ? message.validFrom.toISOString()
          : null);
    message.validThrough !== undefined &&
      (obj.validThrough =
        message.validThrough !== undefined
          ? message.validThrough.toISOString()
          : null);
    return obj;
  },

  fromPartial(
    object: DeepPartial<BusinessHoursListResponse_Hours>
  ): BusinessHoursListResponse_Hours {
    const message = {
      ...baseBusinessHoursListResponse_Hours,
    } as BusinessHoursListResponse_Hours;
    if (object.weekday !== undefined && object.weekday !== null) {
      message.weekday = object.weekday;
    } else {
      message.weekday = 0;
    }
    if (object.open !== undefined && object.open !== null) {
      message.open = BusinessHoursListResponse_Hours_Time.fromPartial(
        object.open
      );
    } else {
      message.open = undefined;
    }
    if (object.close !== undefined && object.close !== null) {
      message.close = BusinessHoursListResponse_Hours_Time.fromPartial(
        object.close
      );
    } else {
      message.close = undefined;
    }
    if (object.validFrom !== undefined && object.validFrom !== null) {
      message.validFrom = object.validFrom;
    } else {
      message.validFrom = undefined;
    }
    if (object.validThrough !== undefined && object.validThrough !== null) {
      message.validThrough = object.validThrough;
    } else {
      message.validThrough = undefined;
    }
    return message;
  },
};

const baseBusinessHoursListResponse_Hours_Time: object = { hour: 0, minute: 0 };

export const BusinessHoursListResponse_Hours_Time = {
  encode(
    message: BusinessHoursListResponse_Hours_Time,
    writer: Writer = Writer.create()
  ): Writer {
    if (message.hour !== 0) {
      writer.uint32(8).int32(message.hour);
    }
    if (message.minute !== 0) {
      writer.uint32(16).int32(message.minute);
    }
    return writer;
  },

  decode(
    input: Reader | Uint8Array,
    length?: number
  ): BusinessHoursListResponse_Hours_Time {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseBusinessHoursListResponse_Hours_Time,
    } as BusinessHoursListResponse_Hours_Time;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.hour = reader.int32();
          break;
        case 2:
          message.minute = reader.int32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): BusinessHoursListResponse_Hours_Time {
    const message = {
      ...baseBusinessHoursListResponse_Hours_Time,
    } as BusinessHoursListResponse_Hours_Time;
    if (object.hour !== undefined && object.hour !== null) {
      message.hour = Number(object.hour);
    } else {
      message.hour = 0;
    }
    if (object.minute !== undefined && object.minute !== null) {
      message.minute = Number(object.minute);
    } else {
      message.minute = 0;
    }
    return message;
  },

  toJSON(message: BusinessHoursListResponse_Hours_Time): unknown {
    const obj: any = {};
    message.hour !== undefined && (obj.hour = message.hour);
    message.minute !== undefined && (obj.minute = message.minute);
    return obj;
  },

  fromPartial(
    object: DeepPartial<BusinessHoursListResponse_Hours_Time>
  ): BusinessHoursListResponse_Hours_Time {
    const message = {
      ...baseBusinessHoursListResponse_Hours_Time,
    } as BusinessHoursListResponse_Hours_Time;
    if (object.hour !== undefined && object.hour !== null) {
      message.hour = object.hour;
    } else {
      message.hour = 0;
    }
    if (object.minute !== undefined && object.minute !== null) {
      message.minute = object.minute;
    } else {
      message.minute = 0;
    }
    return message;
  },
};

const baseFindProductTokenRequest: object = { sku: "", origin: "" };

export const FindProductTokenRequest = {
  encode(
    message: FindProductTokenRequest,
    writer: Writer = Writer.create()
  ): Writer {
    if (message.sku !== "") {
      writer.uint32(10).string(message.sku);
    }
    if (message.origin !== "") {
      writer.uint32(18).string(message.origin);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): FindProductTokenRequest {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseFindProductTokenRequest,
    } as FindProductTokenRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.sku = reader.string();
          break;
        case 2:
          message.origin = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): FindProductTokenRequest {
    const message = {
      ...baseFindProductTokenRequest,
    } as FindProductTokenRequest;
    if (object.sku !== undefined && object.sku !== null) {
      message.sku = String(object.sku);
    } else {
      message.sku = "";
    }
    if (object.origin !== undefined && object.origin !== null) {
      message.origin = String(object.origin);
    } else {
      message.origin = "";
    }
    return message;
  },

  toJSON(message: FindProductTokenRequest): unknown {
    const obj: any = {};
    message.sku !== undefined && (obj.sku = message.sku);
    message.origin !== undefined && (obj.origin = message.origin);
    return obj;
  },

  fromPartial(
    object: DeepPartial<FindProductTokenRequest>
  ): FindProductTokenRequest {
    const message = {
      ...baseFindProductTokenRequest,
    } as FindProductTokenRequest;
    if (object.sku !== undefined && object.sku !== null) {
      message.sku = object.sku;
    } else {
      message.sku = "";
    }
    if (object.origin !== undefined && object.origin !== null) {
      message.origin = object.origin;
    } else {
      message.origin = "";
    }
    return message;
  },
};

const baseFindProductTokenResponse: object = { token: "" };

export const FindProductTokenResponse = {
  encode(
    message: FindProductTokenResponse,
    writer: Writer = Writer.create()
  ): Writer {
    if (message.token !== "") {
      writer.uint32(10).string(message.token);
    }
    return writer;
  },

  decode(
    input: Reader | Uint8Array,
    length?: number
  ): FindProductTokenResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseFindProductTokenResponse,
    } as FindProductTokenResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.token = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): FindProductTokenResponse {
    const message = {
      ...baseFindProductTokenResponse,
    } as FindProductTokenResponse;
    if (object.token !== undefined && object.token !== null) {
      message.token = String(object.token);
    } else {
      message.token = "";
    }
    return message;
  },

  toJSON(message: FindProductTokenResponse): unknown {
    const obj: any = {};
    message.token !== undefined && (obj.token = message.token);
    return obj;
  },

  fromPartial(
    object: DeepPartial<FindProductTokenResponse>
  ): FindProductTokenResponse {
    const message = {
      ...baseFindProductTokenResponse,
    } as FindProductTokenResponse;
    if (object.token !== undefined && object.token !== null) {
      message.token = object.token;
    } else {
      message.token = "";
    }
    return message;
  },
};

export interface Order {
  Place(request: OrderRequest): Promise<OrderResponse>;
}

export class OrderClientImpl implements Order {
  private readonly rpc: Rpc;
  constructor(rpc: Rpc) {
    this.rpc = rpc;
  }
  Place(request: OrderRequest): Promise<OrderResponse> {
    const data = OrderRequest.encode(request).finish();
    const promise = this.rpc.request(
      "shopaholic.service.v1.Order",
      "Place",
      data
    );
    return promise.then((data) => OrderResponse.decode(new Reader(data)));
  }
}

export interface Timetable {
  Request(request: EventCreateRequest): Promise<EventCreateResponse>;
  Commit(request: EventCommitRequest): Promise<EventCommitResponse>;
  Cancel(request: EventCancelRequest): Promise<EventCancelResponse>;
  BusinessHours(
    request: BusinessHoursListRequest
  ): Promise<BusinessHoursListResponse>;
}

export class TimetableClientImpl implements Timetable {
  private readonly rpc: Rpc;
  constructor(rpc: Rpc) {
    this.rpc = rpc;
  }
  Request(request: EventCreateRequest): Promise<EventCreateResponse> {
    const data = EventCreateRequest.encode(request).finish();
    const promise = this.rpc.request(
      "shopaholic.service.v1.Timetable",
      "Request",
      data
    );
    return promise.then((data) => EventCreateResponse.decode(new Reader(data)));
  }

  Commit(request: EventCommitRequest): Promise<EventCommitResponse> {
    const data = EventCommitRequest.encode(request).finish();
    const promise = this.rpc.request(
      "shopaholic.service.v1.Timetable",
      "Commit",
      data
    );
    return promise.then((data) => EventCommitResponse.decode(new Reader(data)));
  }

  Cancel(request: EventCancelRequest): Promise<EventCancelResponse> {
    const data = EventCancelRequest.encode(request).finish();
    const promise = this.rpc.request(
      "shopaholic.service.v1.Timetable",
      "Cancel",
      data
    );
    return promise.then((data) => EventCancelResponse.decode(new Reader(data)));
  }

  BusinessHours(
    request: BusinessHoursListRequest
  ): Promise<BusinessHoursListResponse> {
    const data = BusinessHoursListRequest.encode(request).finish();
    const promise = this.rpc.request(
      "shopaholic.service.v1.Timetable",
      "BusinessHours",
      data
    );
    return promise.then((data) =>
      BusinessHoursListResponse.decode(new Reader(data))
    );
  }
}

export interface Products {
  FindProductToken(
    request: FindProductTokenRequest
  ): Promise<FindProductTokenResponse>;
}

export class ProductsClientImpl implements Products {
  private readonly rpc: Rpc;
  constructor(rpc: Rpc) {
    this.rpc = rpc;
  }
  FindProductToken(
    request: FindProductTokenRequest
  ): Promise<FindProductTokenResponse> {
    const data = FindProductTokenRequest.encode(request).finish();
    const promise = this.rpc.request(
      "shopaholic.service.v1.Products",
      "FindProductToken",
      data
    );
    return promise.then((data) =>
      FindProductTokenResponse.decode(new Reader(data))
    );
  }
}

interface Rpc {
  request(
    service: string,
    method: string,
    data: Uint8Array
  ): Promise<Uint8Array>;
}

type Builtin = Date | Function | Uint8Array | string | number | undefined;
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}
