import Decimal from "decimal.js";
import { DateRange } from "./date_range";

type BaseProduct = {
  SKU: string;
  name: string;
  description: string;
  category?: string;
  gratuity?: boolean;
};

type TokenProduct = BaseProduct & {
  fees: TokenFee[];
  fee_label?: string;
  options?: TokenOption[];
  shipping?: string;
  no_shipping?: boolean;
  lead_time?: number;
  availability?: DateRange[];
};

export type Product = BaseProduct & {
  token: string;
  fees: Fee[];
  feeLabel?: string;
  options?: Option[];
  shipping?: Decimal;
  noShipping: boolean;
  leadTime?: number;
  availability?: DateRange[];
};

export type TokenOption = {
  SKU: string;
  name: string;
  fees?: TokenFee[];
};

type TokenFee = {
  description: string;
  price: string;
  tax: string;
  hidden?: boolean;
};

export type Fee = {
  description: string;
  price: Decimal;
  tax?: Decimal;
  hidden?: boolean;
};

export type Option = {
  SKU: string;
  name: string;
  fees?: Fee[];
};

// productFromToken returns a product structure loaded from a token. A token encodes
// product details for inclusion on a page.
export function productFromToken(token: string): Product {
  const product = JSON.parse(atob(token).slice(32)) as TokenProduct;
  return {
    token: token,
    SKU: product.SKU,
    name: product.name,
    description: product.description,
    options: product.options?.map((option) => optionFromToken(option)),
    fees: product.fees.map((fee) => feeFromTokenFee(fee)),
    feeLabel: product.fee_label,
    shipping: product.shipping ? new Decimal(product.shipping) : undefined,
    noShipping: product.no_shipping || false,
    gratuity: product.gratuity || false,
    leadTime: product.lead_time,
    availability: product.availability,
  };
}

// optionFromToken returns an Option structure from the serialized token option.
export function optionFromToken(option: TokenOption): Option {
  return {
    ...option,
    fees: option.fees?.map((fee) => feeFromTokenFee(fee)),
  };
}

// feeFromTokenFee returns a Fee structure from the serialized fee.
function feeFromTokenFee(fee: TokenFee): Fee {
  return {
    description: fee.description,
    price: new Decimal(fee.price),
    tax: fee.tax !== "0" ? new Decimal(fee.tax) : undefined,
    hidden: fee.hidden,
  };
}

// visibleFeeSubtotal returns the price of the product, taxes and hidden fees excluded. Hidden fees may include fees
// such as deposits. This function is intended for showing the initial price before adding to the cart.
export function visibleFeeSubtotal(fees: Fee[]): Decimal {
  let sum = new Decimal(0);
  for (const fee of fees) {
    if (fee.hidden) {
      continue;
    }
    sum = sum.add(fee.price);
  }
  return sum;
}

// productSubtotal returns the price of the product, taxes excluded.
export function feeSubtotal(fees: Fee[]): Decimal {
  let sum = new Decimal(0);
  for (const fee of fees) {
    sum = sum.add(fee.price);
  }
  return sum;
}

// productTax returns the tax owing for the product.
export function feeTax(fees: Fee[]): Decimal {
  let sum = new Decimal(0);
  for (const fee of fees) {
    if (fee.tax) {
      sum = sum.add(fee.price.mul(fee.tax));
    }
  }
  return sum;
}

// productSubtotal returns the price of the product including taxes.
export function feeTotal(fees: Fee[]): Decimal {
  return feeSubtotal(fees).add(feeTax(fees));
}
