import { ConveyanceType, Order } from "../models/order";
import { OrderItem } from "../models/orderItem";
import { optionFromToken, productFromToken } from "../models/product";

const localStorageOrderKey = "shopaholic_order";
export const defaultOrder: Order = {
  id: undefined,
  conveyanceType: ConveyanceType.Ship,
  items: [],
};

// load returns the order stored in localStorage.
export function load(): Order {
  try {
    const data = localStorage.getItem(localStorageOrderKey);
    if (!data) {
      throw "Data empty";
    }

    const [order, date] = deserialize(data);
    date.setDate(date.getDate() + 2); // Cart valid for two days.
    if (date < new Date()) {
      return { ...defaultOrder };
    }
    return order;
  } catch (err) {
    return { ...defaultOrder };
  }
}

// store persists the supplied order to localStorage.
export function store(order: Order) {
  localStorage.setItem(localStorageOrderKey, serialize(order));
}

// SerializedOrder identifies the structure of the serialized object stored in local storage.
type SerializedOrder = {
  timestamp?: number;
  order?: Partial<Order>;
};

// serialize returns a string representation of the order for storage.
function serialize(order: Order): string {
  const timestamp = new Date().getTime() / 1000;
  return JSON.stringify({ order, timestamp });
}

// deserialize returns the object representation from a serialized string.
function deserialize(data: string): [Order, Date] {
  const { order, timestamp } = JSON.parse(data) as SerializedOrder;

  if (!order) {
    return [{ ...defaultOrder }, new Date(0)];
  }

  let items: OrderItem[] = [];
  if (order.items) {
    items = order.items.map((item: any) => ({
      product: productFromToken(item.product.token),
      option: item.option ? optionFromToken(item.option) : undefined,
      quantity: item.quantity,
      instructions: item.instructions,
    }));
  }

  return [
    {
      id: order.id,
      conveyanceType: order.conveyanceType || defaultOrder.conveyanceType,
      items,
    },
    new Date((timestamp || 0) * 1000),
  ];
}
