import * as React from "react";

import { ConveyanceType, orderIsTimeSensitive } from "../../models/order";
import { OrderProviderProps, Step } from "./OrderController";

import Button from "../../components/core/Button";
import ButtonGroup from "../../components/core/ButtonGroup";
import Padded from "../../components/core/Padded";
import Screen from "../../components/core/Screen";
import Ship from "../../components/orders/Ship";
import { formatDateTime } from "../../util/date";

interface ThanksControllerProps extends OrderProviderProps {}

// ThanksController gives thanks for the order.
export default function ThanksController({ order, onNavigate }: ThanksControllerProps) {
  function onNext() {
    onNavigate(Step.Shop);
  }

  let message: React.ReactNode;
  switch (order.conveyanceType) {
    case ConveyanceType.LocalDelivery:
      if (orderIsTimeSensitive(order) && order.timeslot) {
        message = (
          <>
            Your order is scheduled for delivery at {formatDateTime(order.timeslot?.time)}. Reference order:{" "}
            <strong>{order.id}</strong>.
          </>
        );
        break;
      }
    // fallthrough

    case ConveyanceType.Ship:
      message = (
        <>
          Your order will be shipped in one to three business days. Your order reference is: <strong>{order.id}</strong>
          .
        </>
      );
      break;

    case ConveyanceType.Pickup:
      if (orderIsTimeSensitive(order) && order.timeslot) {
        message = (
          <>
            Your order is scheduled for {formatDateTime(order.timeslot?.time)}. Reference order:{" "}
            <strong>{order.id}</strong>.
          </>
        );
      } else {
        message = (
          <>
            Your order will be available for pickup during regular business hours. Reference order:{" "}
            <strong>{order.id}</strong>.
          </>
        );
      }
      break;
  }

  return (
    <Screen>
      <Padded>
        <h1>Thank You!</h1>
        <p>Thank you for your order. {message}</p>
      </Padded>

      <Ship />

      <ButtonGroup>
        <Button onClick={onNext} wide actionable>
          Finish
        </Button>
      </ButtonGroup>
    </Screen>
  );
}
