import * as React from "react";

import { OrderProviderProps, Step } from "./OrderController";
import { formatDateTime, formatTime } from "../../util/date";

import BusinessHoursController from "../app/BusinessHoursController";
import Button from "../../components/core/Button";
import ButtonGroup from "../../components/core/ButtonGroup";
import DateTimePicker from "../../components/app/DateTimePicker";
import Message from "../../components/core/Message";
import Padded from "../../components/core/Padded";
import Screen from "../../components/core/Screen";
import { Timeslot } from "../../models/timeslot";
import { orderLeadTime, orderProductsAvailability } from "../../models/order";
import { requestTimeslotOffset } from "../../service/timetable";
import { setFulfillmentEvent } from "../../hooks/Order";

interface ConfirmTimeControllerProps extends OrderProviderProps {}

// ConfirmTimeController enables the user to confirm a time that was suggested by the timetable service.
export default function ConfirmTimeController({ order, onNavigate }: ConfirmTimeControllerProps): JSX.Element {
  const timeslot = order.timeslot!;

  const [selectedTime, setSelectedTime] = React.useState(timeslot.time);
  const [loading, setLoading] = React.useState(false);

  function onBack() {
    onNavigate(Step.FindTime);
  }

  function onNext() {
    onNavigate(Step.Pay);
  }

  async function onRequest() {
    try {
      setLoading(true);

      const timeslot = await requestTimeslotOffset(
        selectedTime,
        orderLeadTime(order),
        orderProductsAvailability(order),
        order.timeslot?.id
      );
      setFulfillmentEvent(timeslot);
      setSelectedTime(timeslot.time);

      if (timeslot.reason === "available") {
        onNavigate(Step.Pay);
      } else if (timeslot.reason === "failed") {
        onNavigate(Step.FindTime);
      }
    } catch (err) {
      alert(err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Screen>
      <Padded>
        <h1>Confirm Time</h1>
        <p>Confirm the time that has been suggested for your order.</p>
      </Padded>

      <Padded>
        <ReasonMessage timeslot={timeslot} />

        <Message kind="time" plain>
          If {formatTime(timeslot.time)} is not suitable you may select a new time.
        </Message>
        <DateTimePicker date={selectedTime} onChange={setSelectedTime} />
        <BusinessHoursController />
      </Padded>

      <ButtonGroup>
        {timeslot.time !== selectedTime && (
          <Button onClick={onRequest} wide actionable disabled={loading}>
            Request new time
          </Button>
        )}
        <Button onClick={onNext} wide actionable>
          Confirm {formatTime(timeslot.time)}
        </Button>
        <Button onClick={onBack} wide>
          Back
        </Button>
      </ButtonGroup>
    </Screen>
  );
}

function ReasonMessage({ timeslot }: { timeslot: Timeslot }): JSX.Element {
  switch (timeslot.reason) {
    case "closed":
      return (
        <Message kind="warning">
          <p>
            Unfortunately, we are closed at your preferred time. May we suggest{" "}
            <strong>{formatDateTime(timeslot.time)}</strong> instead?
          </p>
        </Message>
      );

    case "busy":
      return (
        <Message kind="warning">
          <p>
            Unfortunately, our of our reservations are full at your preferred time. May we suggest{" "}
            <strong>{formatDateTime(timeslot.time)}</strong> instead?
          </p>
        </Message>
      );

    case "past":
      return (
        <Message kind="warning">
          <p>
            Unfortunately, your preferred time is in the past. May we suggest{" "}
            <strong>{formatDateTime(timeslot.time)}</strong> instead?
          </p>
        </Message>
      );

    case "unavailable":
      return (
        <Message kind="warning">
          <p>
            Unfortunately, your preferred time is not within the available date range. May we suggest{" "}
            <strong>{formatDateTime(timeslot.time)}</strong> instead?
          </p>
        </Message>
      );

    case "failed":
      return (
        <Message kind="warning">
          <p>Unfortunately, we were unable to reserve a time for you. Please try again.</p>
        </Message>
      );

    default:
      return <></>;
  }
}
