import * as React from "react";
import * as classes from "./CartItem.css";

import { Option, Product } from "../../models/product";
import { OrderItem, itemFees, itemSubtotal } from "../../models/orderItem";

import Button from "../core/Button";
import { formatAmount } from "../../util/currency";

interface CartItemProps {
  item: OrderItem;
  onChange(product: Product, option: Option | undefined, quantity: number): void;
  onRemove(item: OrderItem): void;
}

// CartItem renders an order item from the cart.
export default function CartItem({ item, onChange, onRemove }: CartItemProps) {
  const [quantity, setQuantity] = React.useState(item.quantity.toString());

  React.useEffect(() => {
    setQuantity(item.quantity.toString());
  }, [item.quantity]);

  function onUpdate() {
    const newQuantity = parseInt(quantity);
    if (newQuantity > 0) {
      onChange(item.product, item.option, newQuantity);
    } else {
      setQuantity(item.quantity.toString());
    }
  }

  function onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.keyCode === 13) {
      onUpdate();
    }
  }

  function onQuantityChange(event: React.FormEvent<HTMLInputElement>) {
    setQuantity(event.currentTarget.value);
  }

  function onHandleRemove() {
    onRemove(item);
  }

  const fees = itemFees(item);
  return (
    <div key={item.product.SKU} className={classes.item}>
      <div className={classes.product}>
        <div>
          <input
            type="number"
            className={classes.quantity}
            value={quantity}
            onChange={onQuantityChange}
            onSubmit={onUpdate}
            onBlur={onUpdate}
            onKeyPress={onKeyPress}
          />
        </div>
        <div>×</div>
        <div>
          <div className={classes.name}>
            {item.product.name}
            {item.option && ` (${item.option.name})`}
          </div>
          <div className={classes.description}>{item.product.description}</div>
        </div>
        <div className={classes.subtotal}>{formatAmount(itemSubtotal(item))}</div>
        <div>
          <Button className={classes.remove} onClick={onHandleRemove} small plain>
            <i className="fas fa-minus-circle"></i>
          </Button>
        </div>
      </div>
      {fees.map((fee) => (
        <div className={classes.fee}>
          <div>{fee.description}</div>
          <div className={classes.subtotal}>{formatAmount(fee.price)}</div>
        </div>
      ))}
    </div>
  );
}
