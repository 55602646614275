import * as React from "react";
import { AddButton } from "../../components/orders/AddButton";

import { addItem } from "../../hooks/Order";
import { Action } from "../../models/action";
import { Option, feeSubtotal, productFromToken, visibleFeeSubtotal } from "../../models/product";
import { trackOrderAction } from "../../service/analytics";
import { formatAmount } from "../../util/currency";

interface AddControllerProps {
  token: string;
}

// AddController provides a buy button.
export default function AddController({ token }: AddControllerProps) {
  const product = productFromToken(token);
  const [option, setOption] = React.useState<Option | undefined>(product.options?.[0]);

  // onClick is called when the user opts to add a product to the cart.
  function onClick() {
    // If the product has options available add the item to the cart with the selected option.
    // When no option is available an undefined option may be used.
    addItem(product, option);

    trackOrderAction(Action.add_to_cart, {
      items: [{ id: product.SKU, name: product.name, price: feeSubtotal(product.fees).toDecimalPlaces(2).toString() }],
    });
  }

  return <AddButton product={product} option={option} onChangeOption={setOption} onClick={onClick} />;
}
