import * as React from "react";

import * as classes from "./Overlay.css";

interface OverlayProps extends React.PropsWithChildren<{}> {}

// Overlay renders a container that injects an overlaid container.
export default function Overlay({ children }: OverlayProps) {
  React.useEffect(() => {
    const overflow = document.body.style.overflow;
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = overflow;
    };
  }, []);

  return <div className={classes.base}>{children}</div>;
}
