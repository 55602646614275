export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const DAYS = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
export const DAYS_SHORT = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

// formatTime returns the supplied date's time in H:MM PM format.
export function formatTime(date: Date): string {
  let hour = date.getHours();
  let meridiem: "AM" | "PM" = "AM";
  if (hour === 0) {
    hour = 12;
  } else if (hour === 12) {
    meridiem = "PM";
  } else if (hour > 12) {
    hour -= 12;
    meridiem = "PM";
  }

  return hour + ":" + pad(date.getMinutes()) + " " + meridiem;
}

// formatDate outputs a human readable date string.
export function formatDateTime(date: Date): string {
  const result =
    DAYS[date.getDay()] +
    ", " +
    MONTHS[date.getMonth()] +
    " " +
    date.getDate() +
    ordinalize(date.getDate()) +
    ", " +
    date.getFullYear() +
    " at " +
    formatTime(date);

  return result;
}

// pad returns a leading zero for the supplied n if n is less than 10.
export function pad(n: number): string {
  if (n < 10) {
    return "0" + n;
  }
  return n.toString();
}

// ordinalize returns the ordinal suffix for the supplied n.
function ordinalize(n: number): string {
  switch (n % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}
