import * as React from "react";

import * as classes from "./Select.css";

interface SelectProps
  extends React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
  selectRef?: React.Ref<HTMLSelectElement>;
}

// Select renders a styled select HTML element.
export default function Select({
  children,
  className: callerClassName,
  selectRef,
  ...selectProps
}: React.PropsWithChildren<SelectProps>): JSX.Element {
  let className = classes.select;
  if (callerClassName) {
    className += ` ${callerClassName}`;
  }

  return (
    <select ref={selectRef} className={className} {...selectProps}>
      {children}
    </select>
  );
}
