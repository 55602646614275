import * as React from "react";
import * as classes from "./FreeShippingCTA.css";

import { Order, freeShipThreshold, orderHasFreeShipping, orderRemainingForFreeShipping } from "../../models/order";

import { formatAmount } from "../../util/currency";

interface FreeShippingCTAProps {
  order: Order;
}

export default function FreeShippingCTA({ order }: FreeShippingCTAProps): JSX.Element | null {
  if (orderHasFreeShipping(order)) {
    return null;
  }

  const remaining = orderRemainingForFreeShipping(order);
  return (
    <>
      Free shipping on orders over {formatAmount(freeShipThreshold)}.
      <div className={classes.remaining}>
        Add <strong>{formatAmount(remaining)}</strong> to your order to qualify for free shipping.
      </div>
    </>
  );
}
