import { OrderItem, itemShipping, itemSubtotal, itemTax, itemTotal } from "./orderItem";

import { Decimal } from "decimal.js";
import { OrderRequest_ConveyanceType } from "../rpc/shopaholic/shopaholic";
import { Timeslot } from "./timeslot";
import { Product } from "./product";

export enum ConveyanceType {
  Pickup = OrderRequest_ConveyanceType.CONVEYANCE_TYPE_PICKUP,
  Ship = OrderRequest_ConveyanceType.CONVEYANCE_TYPE_SHIP,
  LocalDelivery = OrderRequest_ConveyanceType.CONVEYANCE_TYPE_LOCAL_DELIVERY,
}

// Order identifies the order the customer has prepared for submission.
export type Order = {
  id?: string;
  conveyanceType: ConveyanceType;
  items: OrderItem[];
  gratuity?: Decimal;
  timeslot?: Timeslot;
};

// orderAllowsShipping returns true if the order can be shipped.
export function orderAllowsShipping(order: Order): boolean {
  for (const item of order.items) {
    if (item.product.noShipping) {
      return false;
    }
  }
  return !orderIsTimeSensitive(order);
}

// orderIsTimeSensitive returns true if the order has time sensitive products.
export function orderIsTimeSensitive(order: Order): boolean {
  for (const item of order.items) {
    if (item.product.leadTime && item.product.leadTime > 0) {
      return true;
    }
    if (item.product.availability && item.product.availability.length > 0) {
      return true;
    }
  }

  return false;
}

// orderLeadTime returns how much lead time is necessary to fulfill the order.
export function orderLeadTime(order: Order): number {
  let du = 0;
  for (const item of order.items) {
    if (item.product.leadTime && du < item.product.leadTime) {
      du = item.product.leadTime;
    }
  }
  return du;
}

// orderProducts returns the products in the order that have availability constraints.
export function orderProductsAvailability(order: Order): Product[] {
  const products = [];
  for (const item of order.items) {
    if (item.product.availability && item.product.availability.length > 0) {
      products.push(item.product);
    }
  }

  return products;
}

// itemCount returns the sum of products selected for purchase in the order.
export function itemCount(order: Order): number {
  let sum = 0;
  for (const item of order.items) {
    sum += item.quantity;
  }
  return sum;
}

// acceptsGratuity identifies an order that accepts gratuities. Acceptance of gratuities is determined by the order
// being time sensitive.
//
// TODO: This was originally determined by a model flag. That methodology should be considered again.
export function acceptsGratuity(order: Order): boolean {
  return orderIsTimeSensitive(order);
}

// orderSubtotal returns the subtotal of the entire order.
export function orderSubtotal(order: Order): Decimal {
  let sum = new Decimal(0);
  for (const item of order.items) {
    sum = sum.add(itemSubtotal(item));
  }
  if (acceptsGratuity(order) && order.gratuity) {
    sum = sum.add(order.gratuity);
  }
  return sum;
}

export const defaultShipAmount = new Decimal(13.5);
export const freeShipThreshold = new Decimal(80);
const shipTaxRate = new Decimal(0.13);

// orderHasFreeShipping returns true if the supplied order qualifies for free shipping.
export function orderHasFreeShipping(order: Order): boolean {
  if (order.conveyanceType === ConveyanceType.Ship) {
    return orderSubtotal(order).greaterThan(freeShipThreshold);
  } else {
    return true;
  }
}

// orderRemainingForFreeShipping returns the amount remaining to qualify for free shipping.
export function orderRemainingForFreeShipping(order: Order): Decimal {
  return freeShipThreshold.sub(orderSubtotal(order));
}

// orderShippingTotal returns the shipping cost for the order for the selected conveyance. The shipping cost is the
// maximum shipping cost of an individual item. Free shipping is applied when the order subtotal exceeds the free
// shipping threshold.
export function orderShippingTotal(order: Order): Decimal {
  if (orderHasFreeShipping(order)) {
    return new Decimal(0);
  }
  return orderShippingCost(order);
}

// orderShippingCost returns the shipping cost when the order. This function differs from orderShippingCost total
// in that the result does not depend on the conveyance type.
export function orderShippingCost(order: Order): Decimal {
  let result = new Decimal(0);
  for (const item of order.items) {
    const amount = itemShipping(item);
    if (amount.greaterThan(result)) {
      result = amount;
    }
  }
  return result;
}

// orderTax returns the amount of the tax on the order.
export function orderTax(order: Order): Decimal {
  let sum = new Decimal(0);
  for (const item of order.items) {
    sum = sum.add(itemTax(item));
  }

  if (order.conveyanceType === ConveyanceType.Ship) {
    sum = sum.add(orderShippingTotal(order).mul(shipTaxRate));
  }

  return sum;
}

// orderTotal returns the amount of the order, including taxes.
export function orderTotal(order: Order): Decimal {
  let sum = new Decimal(0);
  for (const item of order.items) {
    sum = sum.add(itemTotal(item));
  }

  if (acceptsGratuity(order) && order.gratuity) {
    sum = sum.add(order.gratuity);
  }
  if (order.conveyanceType === ConveyanceType.Ship) {
    const shipping = orderShippingTotal(order).mul(shipTaxRate.add(new Decimal(1)));
    sum = sum.add(shipping);
  }

  return sum;
}

// orderTotalForGratuity returns the amount of the order for calculating a percentage tip.
export function orderTotalForGratuity(order: Order): Decimal {
  let sum = new Decimal(0);
  for (const item of order.items) {
    sum = sum.add(itemTotal(item));
  }
  return sum;
}

// orderTimeslot returns the time for the order. If the order does not have a timeslot, the first available
// product availability is returned. If no product availability is available, the current time is returned.
export function orderTimeslot(order: Order): Date {
  if (order.timeslot) {
    return order.timeslot.time;
  }

  for (const item of order.items) {
    if (item.product.availability && item.product.availability.length > 0) {
      return new Date(item.product.availability[0].start);
    }
  }

  return new Date();
}
