// getErrorMessage attempts to extract a human-readable error message from the result of a catch block.
// If a message was unable to be determined an unknown error message will be given.
export function getErrorMessage(error: any): string | string[] {
  if (!error) {
    return "No error";
  }

  if (typeof error === "string" || Array.isArray(error)) {
    return error;
  }
  if ("message" in error) {
    return error.message;
  }
  if ("toString" in error) {
    return error.toString();
  }

  return "An unknown error occurred";
}
