import * as React from "react";
import * as classes from "./CartButton.css";

import Button from "../core/Button";

interface CartButtonProps {
  quantity: number;
  disabled?: boolean;
  onClick(): void;
}

// CartButton renders a floating button that opens the shopping cart.
export default function CartButton({ quantity, onClick, disabled }: CartButtonProps) {
  const [bottom, setBottom] = React.useState(false);
  const [hasBeenBottom, setHasBeenBottom] = React.useState(false);

  const offset = 250;
  function onScroll() {
    setBottom(scrollY > offset);
  }

  React.useEffect(() => {
    onScroll();
    addEventListener("scroll", onScroll);
    return () => removeEventListener("scroll", onScroll);
  }, []);
  React.useEffect(() => {
    if (bottom) {
      setHasBeenBottom(true);
    }
  }, [bottom]);

  const classNames = [classes.floater];
  if (bottom !== undefined) {
    if (bottom) {
      classNames.push(classes.floaterBottom);
    } else if (hasBeenBottom) {
      classNames.push(classes.floaterTop);
    }
  }

  return (
    <div className={classNames.join(" ")}>
      <Button onClick={onClick} disabled={disabled} plain>
        <div className={classes.container}>
          <i className="fas fa-shopping-cart"></i>
          <span className={classes.quantity}>{quantity}</span>
        </div>
      </Button>
    </div>
  );
}
