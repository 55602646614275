import * as React from "react";

import * as classes from "./Button.css";

interface ButtonProps {
  className?: string;
  onClick?(event?: React.MouseEvent<HTMLButtonElement>): void;
  disabled?: boolean;
  small?: boolean;
  wide?: boolean;
  actionable?: boolean;
  plain?: boolean;
}

// Button renders a button.
export default function Button({
  children,
  onClick,
  disabled,
  small,
  wide,
  actionable,
  plain,
  className: parentClassName,
}: React.PropsWithChildren<ButtonProps>) {
  const className = [
    classes.button,
    small && classes.small,
    wide && classes.wide,
    actionable && classes.actionable,
    plain && classes.plain,
    !disabled && classes.enabled,
    parentClassName,
  ].join(" ");
  return (
    <button className={className} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
}
