import * as React from "react";
import * as classes from "./Split.css";

interface SplitProps {}

// Split renders child elements side by side.
export default function Split({
  children
}: React.PropsWithChildren<SplitProps>) {
  return <div className={classes.container}>{children}</div>;
}
