import * as React from "react";

interface FieldProps extends React.HTMLAttributes<HTMLDivElement> {
  name?: string | React.ReactNode;
}

// Field renders an input field.
export default function Field({
  name,
  children,
  ...props
}: React.PropsWithChildren<FieldProps>) {
  return (
    <div {...props}>
      <label>
        {name && <div>{name}</div>}
        {children}
      </label>
    </div>
  );
}
