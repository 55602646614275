import * as React from "react";
import * as classes from "./ButtonGroup.css";

interface ButtonGroupProps {}

// ButtonGroup renders a group of control buttons.
export default function ButtonGroup({
  children
}: React.PropsWithChildren<ButtonGroupProps>) {
  return <div className={classes.controls}>{children}</div>;
}
