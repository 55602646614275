import Decimal from "decimal.js";
import * as React from "react";

import { ConveyanceType, Order, orderAllowsShipping } from "../models/order";
import { OrderItem } from "../models/orderItem";
import { Option, Product } from "../models/product";
import { Timeslot } from "../models/timeslot";
import { defaultOrder, load, store } from "./localStorage";

let order: Order = load();
const OrderContext = React.createContext<[Order, number]>([order, 0]);

export function useOrder(): Order {
  const [order, _] = React.useContext(OrderContext);
  return order;
}

export function useOrderVersion(): number {
  const [_, version] = React.useContext(OrderContext);
  return version;
}

// OrderProvider is a wrapper that propigates changes to the order down to
// any child components that subscribe to the order context.
export function OrderProvider({ children }: React.PropsWithChildren<{}>) {
  const [counter, setCounter] = React.useState(0);

  React.useEffect(() => {
    listener = () => setCounter(counter + 1);
    return () => (listener = undefined);
  }, [counter]);

  return <OrderContext.Provider value={[order, counter]}>{children}</OrderContext.Provider>;
}

let listener: (() => void) | undefined;
function commit() {
  store(order);
  listener && listener();
}

// setItem updates the order item matching the supplied product with the number
// of quantity items.
export function setItem(product: Product, option: Option | undefined, quantity: number) {
  const item = order.items.find((item) => isSameItem(item, product, option));
  if (quantity < 1) {
    order.items = order.items.filter((item) => !isSameItem(item, product, option));
  } else if (item) {
    item.quantity = quantity;
  } else {
    order.items = [
      ...order.items,
      {
        product: product,
        quantity: quantity,
        option: option,
      },
    ];
  }

  commit();
}

// addItem adds one more item to the cart for the supplied product.
export function addItem(product: Product, option: Option | undefined) {
  const item = order.items.find((item) => isSameItem(item, product, option));
  if (item) {
    setItem(product, option, item.quantity + 1);
  } else {
    setItem(product, option, 1);
  }

  // Pick the pickup conveyance type if the order does not accept shipping and the order is set to ship.
  if (!orderAllowsShipping(order) && order.conveyanceType == ConveyanceType.Ship) {
    setConveyanceType(ConveyanceType.Pickup);
  }
}

// isSameItem returns true if the supplied order item matches the selected product/option.
function isSameItem(item: OrderItem, product: Product, option: Option | undefined): boolean {
  if (!option) {
    return item.product.SKU === product.SKU && !item.option;
  }
  return item.product.SKU === product.SKU && !!item.option && item.option.SKU === option.SKU;
}

export function setOrderID(id: string) {
  order.id = id;
  commit();
}

export function setConveyanceType(type: ConveyanceType) {
  order.conveyanceType = type;
  commit();
}

// setGratuity assigns a tip amount to the order.
export function setGratuity(amount: Decimal) {
  order.gratuity = amount;
  commit();
}

// setFulfillment time assigns the fulfillment time to the active order.
export function setFulfillmentEvent(timeslot: Timeslot) {
  order.timeslot = timeslot;
  commit();
}

// resetOrder resets an order to its initial state.
export function resetOrder() {
  order = { ...defaultOrder };
  commit();
}
