import * as React from "react";

import { Order, itemCount } from "../../models/order";
import { resetOrder, useOrder, useOrderVersion } from "../../hooks/Order";

import { Action } from "../../models/action";
import CartButton from "../../components/orders/CartButton";
import CartController from "./CartController";
import ConfirmTimeController from "./ConfirmTimeController";
import FindTimeController from "./FindTimeController";
import Overlay from "../../components/core/Overlay";
import PaymentController from "./PaymentController";
import ThanksController from "./ThanksController";
import { trackOrderAction } from "../../service/analytics";

interface CartControllerProps {}

// OrderProviderProps identifies the propers that are to be used by order provider controllers.
export interface OrderProviderProps {
  order: Order;
  onNavigate(step: Step): void;
}

// Step identifies the steps the user works through during the order checkout process.
export enum Step {
  Shop = 0,
  FindTime,
  ConfirmTime,
  Cart,
  Pay,
  Finish,
}

// Setup of the screens available.
const providers: {
  [key in Step]: undefined | ((props: OrderProviderProps) => JSX.Element);
} = {
  [Step.Shop]: undefined,
  [Step.FindTime]: FindTimeController,
  [Step.ConfirmTime]: ConfirmTimeController,
  [Step.Cart]: CartController,
  [Step.Pay]: PaymentController,
  [Step.Finish]: ThanksController,
};

// CartController provides shopping cart functionality.
export default function OrderController(props: CartControllerProps) {
  const order = useOrder();
  const version = useOrderVersion();
  const [step, setStep] = React.useState(Step.Shop);
  const [lastOrder, setLastOrder] = React.useState<Order | undefined>();

  // showCart is called when the user activates the cart. Activation can happen by pressing on the cart icon
  // or adding a product to the cart.
  function showCart() {
    setStep(Step.Cart);
  }

  // Present the user with the cart when the cart changes. When the user returns to a cart after a page reload
  // the cart will not appear before user action. The actions are ignored when in the finish step state.
  const count = itemCount(order);
  React.useEffect(() => {
    if (version === 0 || step === Step.Finish) {
      return;
    }

    if (count === 0) {
      setStep(Step.Shop);
    } else {
      showCart();
    }
  }, [count]);

  // React to step state changes. The cart is reset when the user enters the finish step.
  React.useEffect(() => {
    trackOrderAction(Action.checkout_progress, { checkout_step: step });

    if (step === Step.Finish) {
      setLastOrder({ ...order });
      resetOrder();
    } else if (lastOrder) {
      setLastOrder(undefined);
    }
  }, [step]);

  // Render with the previous order object when the order is finished. The active order is reset when the state
  // changes and this allows the provider to display information from the previously submitted order.
  let renderOrder = order;
  if (step === Step.Finish && lastOrder) {
    renderOrder = lastOrder;
  }

  const Provider = providers[step];
  return (
    <div>
      {Provider && (
        <Overlay>
          <Provider order={renderOrder} onNavigate={setStep} />
        </Overlay>
      )}
      {count && <CartButton quantity={count} onClick={showCart} />}
    </div>
  );
}
