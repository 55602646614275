import * as React from "react";
import * as classes from "./Padded.css";

interface PaddedProps {}

// Padded renders the children in a padded container.
export default function Padded({
  children,
  className,
  ...props
}: React.PropsWithChildren<PaddedProps> & React.HTMLProps<HTMLDivElement>) {
  return (
    <div className={[classes.container, className].join(" ")} {...props}>
      {children}
    </div>
  );
}
