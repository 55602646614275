import * as React from "react";
import * as classes from "./Pay.css";

import { CardElement, useElements } from "@stripe/react-stripe-js";
import {
  ConveyanceType,
  Order,
  acceptsGratuity,
  orderShippingTotal,
  orderTotal,
  orderTotalForGratuity,
} from "../../models/order";
import { setConveyanceType, setGratuity } from "../../hooks/Order";

import Button from "../core/Button";
import ButtonGroup from "../core/ButtonGroup";
import Decimal from "decimal.js";
import Deemphasized from "../core/Deemphasized";
import Divider from "../core/Divider";
import Field from "../core/Field";
import Heading from "../core/Heading";
import Input from "../core/Input";
import Message from "../core/Message";
import Padded from "../core/Padded";
import { PaymentInfo } from "../../models/payment";
import Screen from "../core/Screen";
import Select from "../core/Select";
import SelectConveyance from "./SelectConveyance";
import Split from "../core/Split";
import { StripeCardElement } from "@stripe/stripe-js";
import { createNumberMask } from "text-mask-addons";
import { formatAmount } from "../../util/currency";
import { formatDateTime } from "../../util/date";
import { readForm } from "../../util/forms";

const localCities = [
  "Arva",
  "Auburn",
  "Bayfield",
  "Belgrave",
  "Blyth", // Expanded area
  "Brucefield",
  "Brussels",
  "Brussels", // Expanded area
  "Cambridge ", // Expanded area
  "Clinton",
  "Dashwood",
  "Denfield", // Expanded area
  "Dublin", // Expanded area
  "Egmondville",
  "Exeter",
  "Forest", // Expanded area
  "Goderich",
  "Grand Bend",
  "Granton",
  "Hay",
  "Hensall",
  "Huron Park", // Expanded area
  "Ilderton", // Expanded area
  "Kippen",
  "Kitchener", // Expanded area
  "Londesborough", // Expanded area
  "London",
  "Lucan",
  "Mitchell", // Expanded area
  "New Hamburg", // Expanded area
  "Point Edward", // Expanded area
  "Port Franks", // Expanded area
  "Sarnia", // Expanded area
  "Seaforth",
  "St. Marys", // Expanded area
  "Staffa",
  "Stratford", // Expanded area
  "Varna",
  "Walton",
  "Wingham", // Expanded area
  "Wroxeter", // Expanded area
  "Zurich",
];

interface PayProps {
  order: Order;
  error?: string | string[];
  onBack(): void;
  onSubmit(paymentInfo: PaymentInfo, cardElement: StripeCardElement, onFinished?: () => void): void;
}

const dollarMask = createNumberMask({ prefix: "$", allowDecimal: true });

// Pay renders a payment form.
export default function Pay({ order, error, onBack, onSubmit }: PayProps) {
  const elements = useElements();
  const formContainerRef = React.useRef<HTMLDivElement>(null);
  const formRef = React.useRef<HTMLFormElement>(null);
  const [submitting, setSubmitting] = React.useState(false);
  const [inputCity, setInputCity] = React.useState("");
  const [verifiedCity, setVerifiedCity] = React.useState(false);
  const [gratuityValue, setGratuityValue] = React.useState<string | undefined>(
    order.gratuity?.toDecimalPlaces(2).toString()
  );

  function onUseShipping(event: React.MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    setConveyanceType(ConveyanceType.Ship);
    setVerifiedCity(false);
  }

  function onUsePickup(event: React.MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    setConveyanceType(ConveyanceType.Pickup);
    setVerifiedCity(false);
  }

  function onVerifyCity(value: string) {
    if (order.conveyanceType !== ConveyanceType.Ship) {
      return;
    }
    for (const city of localCities) {
      const m1 = city.toLowerCase().trim();
      const m2 = value.toLowerCase().trim();
      if (m1 === m2) {
        setConveyanceType(ConveyanceType.LocalDelivery);
        setInputCity(city);
        setVerifiedCity(true);
        return;
      }
    }
  }

  function onChangeGratuity(value: string) {
    if (value.length === 0) {
      setGratuity(new Decimal(0));
      setGratuityValue(undefined);
      return;
    }

    // Remove non-digit/decimal characters so that the input can be read.
    let amount = new Decimal(value.replace(/[^\d\.]/g, ""));
    if (amount.isNegative()) {
      amount = amount.neg();
    }

    setGratuity(amount);
    setGratuityValue(amount.toDecimalPlaces(2).toString());
  }

  // tip applies a tip rate.
  function tip(rate: number): (event: React.MouseEvent<HTMLButtonElement>) => void {
    return function (event) {
      event.preventDefault();
      const tip = new Decimal(rate).mul(orderTotalForGratuity(order));
      setGratuity(tip.toDecimalPlaces(2));
      setGratuityValue(tip.toDecimalPlaces(2).toString());
    };
  }

  function onSubmitForm() {
    setSubmitting(true);
  }

  function errors(): string | React.ReactNode {
    if (!Array.isArray(error)) {
      return error;
    }

    return (
      <div>
        <strong>Sorry, some more information is necessary for us to process your order:</strong>
        <ul>
          {error.map((item) => (
            <li>{item}</li>
          ))}
        </ul>
      </div>
    );
  }

  // Begin the payment process upon the Submit button being pressed.
  React.useEffect(() => {
    if (!submitting || !formRef.current || !elements) {
      return;
    }

    const paymentInfo = readForm(formRef.current) as PaymentInfo;
    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      return;
    }
    onSubmit(paymentInfo, cardElement, () => setSubmitting(false));
  }, [submitting]);

  // Scroll the top when errors are presented so that the user can see them.
  React.useEffect(() => {
    if (!formContainerRef.current) {
      return;
    }
    formContainerRef.current.scrollTo(0, 0);
  }, [error]);

  let cityInput = (
    <Input name="shipping_city" placeholder="Bayfield" defaultValue={inputCity} onValueChange={onVerifyCity} />
  );
  if (order.conveyanceType === ConveyanceType.LocalDelivery) {
    const shipping = orderShippingTotal(order);
    cityInput = (
      <div>
        <Select name="shipping_city" defaultValue={inputCity}>
          {localCities.map((city) => (
            <option key={city}>{city}</option>
          ))}
        </Select>

        {!shipping.isZero() && (
          <Deemphasized>
            {verifiedCity ? (
              <p>
                Your address qualifies for free local delivery. The {formatAmount(shipping)} + tax shipping rate has
                been discounted from your order. You may still{" "}
                <a href="#" onClick={onUseShipping}>
                  ship your order for {formatAmount(shipping)} + tax
                </a>{" "}
                or{" "}
                <a href="#" onClick={onUsePickup}>
                  pick up your order
                </a>{" "}
                during regular business hours.
              </p>
            ) : (
              <p>
                Unable to find the city you are looking for? You may be outside of our delivery area. Use the{" "}
                <a href="#" onClick={onUseShipping}>
                  {formatAmount(shipping)} (+tax) flat rate shipping option
                </a>
                .
              </p>
            )}
          </Deemphasized>
        )}
      </div>
    );
  }

  return (
    <Screen>
      <Padded>
        <h1>Checkout</h1>

        <p>
          We need just a little more information in order to complete the transaction. Please fill out the information
          below.
        </p>
      </Padded>

      <div ref={formContainerRef} className={classes.form}>
        {error && (
          <Padded>
            <Message kind="error">{errors()}</Message>
          </Padded>
        )}

        <form ref={formRef}>
          <Padded>
            <Split>
              <Field name="First name">
                <Input name="first_name" placeholder="First" />
              </Field>

              <Field name="Last name">
                <Input name="last_name" placeholder="Last" />
              </Field>
            </Split>
          </Padded>

          <Padded>
            <Split>
              <Field name="Email">
                <Input name="email" placeholder="you@bayfieldbrewingco.com" />
              </Field>

              <Field name="Phone">
                <Input name="phone" placeholder="555-123-4567" />
              </Field>
            </Split>
          </Padded>

          <Divider />

          {acceptsGratuity(order) && (
            <>
              <Padded className={classes.gratuity}>
                <p>If you would like, you may add an optional tip for your server.</p>
                <Input
                  mask={dollarMask}
                  className={classes.narrow}
                  name="tip"
                  placeholder="$0.00"
                  value={gratuityValue}
                  onValueChange={onChangeGratuity}
                  autoComplete="off"
                />

                <Button className={classes.tipButton} onClick={tip(0.1)}>
                  10%
                </Button>
                <Button className={classes.tipButton} onClick={tip(0.15)}>
                  15%
                </Button>
                <Button className={classes.tipButton} onClick={tip(0.2)}>
                  20%
                </Button>
              </Padded>

              <Divider />
            </>
          )}

          <Padded>
            <Message kind="notice" plain>
              Your total comes to <strong>{formatAmount(orderTotal(order))}</strong>
              {order.timeslot && (
                <>
                  <br />
                  Scheduled for {formatDateTime(order.timeslot.time)}
                </>
              )}
            </Message>
            <Field>
              <CardElement
                className={classes.base}
                options={{
                  style: {
                    base: {
                      color: "#666666",
                      fontSize: "16px",
                    },
                  },
                  hidePostalCode: true,
                }}
              />
            </Field>
          </Padded>

          <Divider />

          <Padded>
            <SelectConveyance order={order} />
          </Padded>

          {order.conveyanceType !== ConveyanceType.Pickup && (
            <>
              <Padded>
                <Heading>Delivery Address</Heading>

                <Split>
                  <Field name="Street 1">
                    <Input name="shipping_street_1" placeholder="123 Main St." />
                  </Field>

                  <Field name="Street 2">
                    <Input name="shipping_street_2" placeholder="PO Box 111" />
                  </Field>
                </Split>
              </Padded>

              <Padded>
                <Split>
                  <Field name="City">{cityInput}</Field>

                  <Field name="Province">
                    <div className={classes.filled}>Ontario</div>
                  </Field>
                </Split>

                <Field className={classes.postalCodeContainer} name="Postal code">
                  <Input className={classes.postalCode} name="shipping_postal_code" placeholder="X1X 0Y0" />
                </Field>
              </Padded>
            </>
          )}

          <Divider />

          <Padded>
            <Heading>Message</Heading>

            <Field>
              <Deemphasized>
                <p>Let us know if there is anything else we should know about your order.</p>
              </Deemphasized>
              <textarea className={classes.message} name="message"></textarea>
            </Field>
          </Padded>
        </form>
      </div>

      <ButtonGroup>
        <Button onClick={onSubmitForm} disabled={submitting} wide actionable>
          {submitting ? "Submitting..." : "Submit Order"}
        </Button>
        <Button onClick={onBack} wide>
          Back
        </Button>
      </ButtonGroup>
    </Screen>
  );
}
