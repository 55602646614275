import Decimal from "decimal.js";

import { defaultShipAmount } from "./order";
import { Fee, Option, Product, feeSubtotal, feeTax, feeTotal } from "./product";

// OrderItem identifies an item selected to be purchased.
export type OrderItem = {
  product: Product;
  option?: Option;
  quantity: number;
  instructions?: string;
};

// itemFees returns the fees applicable to the order item. A product option fee set is used, when available, otherwise
// the product fees are applied.
export function itemFees(item: OrderItem): Fee[] {
  if (item.option && item.option.fees && item.option.fees.length > 0) {
    return item.option.fees;
  }
  return item.product.fees;
}

// subtotal returns the price of the order item.
export function itemSubtotal(item: OrderItem): Decimal {
  const fees = itemFees(item);
  return feeSubtotal(fees).mul(item.quantity);
}

// itemShipping returns the shipping cost for the item.
export function itemShipping(item: OrderItem): Decimal {
  if (!item.product.shipping) {
    return defaultShipAmount;
  }
  return item.product.shipping;
}

// itemTax returns the tax due for the order item.
export function itemTax(item: OrderItem): Decimal {
  const fees = itemFees(item);
  return feeTax(fees).mul(item.quantity);
}

// subtotal returns the price of the order item with taxes.
export function itemTotal(item: OrderItem): Decimal {
  const fees = itemFees(item);
  return feeTotal(fees).mul(item.quantity);
}
