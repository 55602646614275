import * as React from "react";
import * as ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { backOff } from "exponential-backoff";

import AddController from "./src/controllers/orders/AddController";
import { Elements } from "@stripe/react-stripe-js";
import OrderController from "./src/controllers/orders/OrderController";
import { OrderProvider } from "./src/hooks/Order";
import { loadStripe } from "@stripe/stripe-js/pure";
import { stripePublicKey, environment } from "./config";
import { findProductToken } from "./src/service/products";

Sentry.init({
  dsn: "https://4f5c697616874f478b2e35eb6ce42010@o1196307.ingest.sentry.io/6320929",
  integrations: [new BrowserTracing()],
  environment,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const fontScript = document.createElement("script");
fontScript.src = "https://kit.fontawesome.com/8a697c07b3.js";
fontScript.crossOrigin = "anonymous";
document.body.append(fontScript);

// Start the main application and insert it into the document.
const stripePromise = backOff(() => loadStripe(stripePublicKey));
const app = document.createElement("div");
document.body.append(app);
ReactDOM.render(
  <Elements stripe={stripePromise}>
    <OrderProvider>
      <OrderController />
    </OrderProvider>
  </Elements>,
  app
);

// Search for product elements in the HTML document. These elements contain the information to construct the shop
// button and provide the product information to the cart.
const products = document.querySelectorAll("[data-product]");
for (const product of Object.values(products)) {
  (async () => {
    const data = product.getAttribute("data-product");
    if (!data) {
      return;
    }

    try {
      let token = data;
      if (token.startsWith("sku:")) {
        token = await findProductToken(location.origin, token.slice(4));
      }

      ReactDOM.render(<AddController token={token} />, product);
    } catch (err) {}
  })();
}
