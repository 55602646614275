import * as React from "react";
import * as classes from "./Calendar.css";

import { DAYS_SHORT, MONTHS } from "../../util/date";

interface CalendarProps {
  date: Date;
  onChange(date: Date): void;
}

export default function Calendar({ date, onChange }: CalendarProps): JSX.Element {
  const month = MONTHS[date.getMonth()];

  // updateDate updates the current date state, notifying the onChange handler if available.
  function updateDate(date: Date) {
    if (!onChange) {
      return;
    }
    onChange(date);
  }

  // onNextMonth sets the date to on in the next month.
  function onNextMonth() {
    const next = new Date(date);
    next.setMonth(date.getMonth() + 1);
    updateDate(next);
  }

  // onNextMonth sets the date to on in the previous month.
  function onPrevMonth() {
    const prev = new Date(date);
    prev.setMonth(date.getMonth() - 1);
    updateDate(prev);
  }

  let current = new Date(date.getFullYear(), date.getMonth(), 1);
  while (current.getDay() !== 0) {
    current.setDate(current.getDate() - 1);
  }
  const rows = [];
  for (let i = 0; i < 6; i++) {
    const row = [];
    for (let j = 0; j < 7; j++) {
      const classNames = [];
      if (current.getMonth() === date.getMonth()) {
        if (current.getDate() === date.getDate()) {
          classNames.push(classes.active);
        }
      } else {
        classNames.push(classes.grey);
      }

      const now = new Date(current);
      row.push(
        <td key={j} className={classNames.join(" ")} onClick={() => updateDate(now)}>
          {current.getDate()}
        </td>
      );
      current.setDate(current.getDate() + 1);
    }
    rows.push(<tr key={i}>{row}</tr>);
  }

  return (
    <div>
      <div className={classes.header}>
        <div onClick={onPrevMonth}>
          <i className="fas fa-arrow-left"></i>
        </div>
        <h1>{month}</h1>
        <div onClick={onNextMonth}>
          <i className="fas fa-arrow-right"></i>
        </div>
      </div>

      <table className={classes.table}>
        <thead>
          {DAYS_SHORT.map((day) => (
            <th key={day}>{day}</th>
          ))}
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
}
