import { Hours, Timeslot } from "./../models/timeslot";
import { Duration } from "../models/duration";
import { Duration as pbDuration } from "../rpc/shopaholic/google/protobuf/duration";
import { TimetableClientImpl } from "../rpc/shopaholic/shopaholic";
import { twirpClient } from "./client";
import { Product } from "../models/product";

// requestTimeslot asks the reservation system if the requested time is available. If the time is not available
// a suggested time will be returned. The reservations placed are only temporary placeholders and must be
// committed to in order to retain the spot.
//
// If a previous request was made the ID returned by that response should be passed to previousResponseID in order
// to clean up the reservation that was pending prior.
export async function requestTimeslot(
  time: Date,
  duration: Duration,
  products: Product[],
  previousResponseID?: string
): Promise<Timeslot> {
  const client = new TimetableClientImpl(twirpClient);
  const response = await client.Request({
    time,
    duration: makeDuration(duration),
    productTokens: products.map((product) => product.token),
    units: 1,
    id: previousResponseID ?? "",
    origin: location.origin,
  });
  if (!response.time) {
    throw new Error("Unable to retrieve time. Please try again later.");
  }
  return { id: response.id, time: response.time, reason: response.reason as Timeslot["reason"] };
}

// requestTimeslotOffset functions like requestTimeslot but transparently offsets the time to appear at the end of
// the reservation instead of the beginning. This is intended to be used for pickup-type orders where the user is
// expected to the pick up the product when the service is complete. This differs from reservations for tables where
// the user expects to arrive at the beginning of the reservation. The latter case should use requestTimeslot instead.
export async function requestTimeslotOffset(
  time: Date,
  duration: Duration,
  products: Product[],
  previousResponseID?: string
): Promise<Timeslot> {
  const offsetTime = new Date(time.getTime() - duration / 1e6);
  const response = await requestTimeslot(offsetTime, duration, products, previousResponseID);
  return { ...response, time: new Date(response.time.getTime() + duration / 1e6) };
}

// commitTimeslot signals to the timetable service that the supplied timeslot reservation is approved by the user
// and should be committed to.
export async function commitTimeslot(timeslot: Timeslot): Promise<void> {
  const client = new TimetableClientImpl(twirpClient);
  await client.Commit({ id: timeslot.id });
}

// loadBusinessHours loads the available business hours used by the timetable service.
export async function loadBusinessHours(): Promise<Hours[]> {
  const client = new TimetableClientImpl(twirpClient);
  const response = await client.BusinessHours({
    origin: location.origin,
  });

  const minDate = new Date(0);
  const maxDate = new Date(8640000000000000);
  return response.hours.map<Hours>((hour) => {
    let validFrom = minDate;
    if (hour.validFrom && hour.validFrom.getFullYear() !== 0) {
      validFrom = hour.validFrom;
    }
    let validThrough = maxDate;
    if (hour.validThrough && hour.validThrough.getFullYear() !== 0) {
      validThrough = hour.validThrough;
    }

    return {
      weekday: hour.weekday,
      open: hour.open || { hour: 0, minute: 0 },
      close: hour.close || { hour: 0, minute: 0 },
      validFrom,
      validThrough,
    };
  });
}

// makeDuration returns a protobuf Duration object for the supplied duration value.
function makeDuration(duration: Duration): pbDuration {
  const seconds = duration / 1e9;
  const nanos = duration - seconds * 1e9;
  return { seconds, nanos };
}
