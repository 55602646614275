import { Token } from "@stripe/stripe-js";

import { ConveyanceType, Order } from "../models/order";
import { PaymentInfo } from "../models/payment";
import * as pb from "../rpc/shopaholic/shopaholic";
import { OrderRequest } from "../rpc/shopaholic/shopaholic";
import { twirpClient } from "./client";

// ANCHOR: Service.

// placeOrder submits an order to the server.
export async function placeOrder(order: Order, paymentInfo: PaymentInfo, stripeToken: Token): Promise<{ id: string }> {
  const request = makeOrderRequest(order, paymentInfo, stripeToken);
  const client = new pb.OrderClientImpl(twirpClient);
  const response = await client.Place(request);
  const { error: requestError, id } = response;
  if (requestError) {
    throw requestError.validationErrors;
  }
  return { id };
}

// ANCHOR: Protobuf Serialization

const conveyanceTypeMap = {
  [ConveyanceType.Ship]: pb.OrderRequest_ConveyanceType.CONVEYANCE_TYPE_SHIP,
  [ConveyanceType.LocalDelivery]: pb.OrderRequest_ConveyanceType.CONVEYANCE_TYPE_LOCAL_DELIVERY,
  [ConveyanceType.Pickup]: pb.OrderRequest_ConveyanceType.CONVEYANCE_TYPE_PICKUP,
};

// makeOrderRequest returns a OrderRequest protobuf object.
export function makeOrderRequest(order: Order, paymentInfo: PaymentInfo, stripeToken: Token): OrderRequest {
  return {
    conveyanceType: conveyanceTypeMap[order.conveyanceType],
    card: stripeToken.id,
    items: order.items.map((orderItem) => ({
      token: orderItem.product.token,
      option: orderItem.option && { sku: orderItem.option.SKU },
      quantity: orderItem.quantity,
      instructions: orderItem.instructions ?? "",
    })),
    paymentType: pb.OrderRequest_PaymentType.PAYMENT_TYPE_CARD,
    message: paymentInfo.message ?? "",
    customer: {
      firstName: paymentInfo.first_name ?? "",
      lastName: paymentInfo.last_name ?? "",
      email: paymentInfo.email ?? "",
      phone: paymentInfo.phone ?? "",
    },
    shippingAddress: {
      street1: paymentInfo.shipping_street_1 ?? "",
      street2: paymentInfo.shipping_street_2 ?? "",
      city: paymentInfo.shipping_city ?? "",
      postalCode: paymentInfo.shipping_postal_code ?? "",
    },
    gratuity: order.gratuity?.toString() ?? "",
    fulfillment: order.timeslot?.time,
    eventID: order.timeslot?.id ?? "",
  };
}
