import axios from "axios";

class TwirpClient {
  url: string;

  constructor(url: string) {
    this.url = url;
  }

  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array> {
    return axios({
      method: "post",
      url: `${this.url}/${service}/${method}`,
      headers: {
        "Content-Type": "application/protobuf",
      },
      responseType: "arraybuffer",
      data: Buffer.from(data),
    }).then((response) => {
      return new Uint8Array(response.data);
    });
  }
}

export const twirpClient = new TwirpClient("/twirp");
