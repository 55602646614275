import * as React from "react";

import * as classes from "./Message.css";

type MessageKind = ("notice" | "warning" | "error" | "time") & keyof typeof classes;

interface MessageProps {
  kind: MessageKind;
  plain?: boolean;
  icon?: boolean;
}

const icons: { [key in MessageKind]: JSX.Element } = {
  notice: <i className="fas fa-check-circle"></i>,
  warning: <i className="fas fa-exclamation-triangle"></i>,
  error: <i className="fas fa-exclamation-circle"></i>,
  time: <i className="far fa-calendar-alt"></i>,
};

// Message renders a styled message.
export default function Message({ kind, plain, icon = true, children }: React.PropsWithChildren<MessageProps>) {
  return (
    <div className={[classes.container, !plain && classes[kind]].join(" ")}>
      {icon && icons[kind]}
      <p>{children}</p>
    </div>
  );
}
