import * as React from "react";
import * as classes from "./Ship.css";

interface ShipProps {}

// Ship renders a shipping icon.
export default function Ship(props: ShipProps) {
  return (
    <div className={classes.container}>
      <i className="fas fa-shipping-fast"></i>
    </div>
  );
}
