import { Action, EventParams } from "./../models/action";

// trackAction sends the action to the analytics service.
export function trackAction(category: string, action: Action, params?: EventParams) {
  if (gtag) {
    gtag("event", "action", { ...params, event_category: category, event_label: action });
  }
}

// trackOrderAction calls trackAction with an order scope. To be used for order-related events.
export function trackOrderAction(action: Action, params?: EventParams) {
  trackAction("orders", action, params);
}
