import * as React from "react";
import * as classes from "./Loading.css";

interface LoadingProps {}

// Loading renders a loading screen.
export default function Loading({ children }: React.PropsWithChildren<LoadingProps>): JSX.Element {
  return (
    <div className={classes.base}>
      <i className="fas fa-box-open"></i>
      <p>{children}</p>
    </div>
  );
}
