import * as React from "react";

import { Option, Product, visibleFeeSubtotal } from "../../models/product";
import { formatAmount } from "../../util/currency";

interface AddButtonProps {
  product: Product;
  option?: Option;
  onChangeOption(option: Option): void;
  onClick(): void;
}

// ButtonState identifies the possible states a button may be in.
export enum ButtonState {
  Normal,
  Added,
}

// AddButton adds an add to cart button.
export function AddButton({ product, onChangeOption, onClick }: AddButtonProps): JSX.Element {
  const [state, setState] = React.useState(ButtonState.Normal);

  function onHandleOptionChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const option = product.options?.find((option) => option.SKU === event.target.value);
    if (option) {
      onChangeOption(option);
    }
  }

  function onHandleClick() {
    if (state === ButtonState.Added) {
      return;
    }
    setState(ButtonState.Added);
    onClick();
  }

  React.useEffect(() => {
    if (state === ButtonState.Added) {
      const timer = setTimeout(() => setState(ButtonState.Normal), 1000);
      return () => clearTimeout(timer);
    }
  }, [state]);

  return (
    <>
      {product.options && (
        <div className="option">
          <select onChange={onHandleOptionChange}>
            {product.options.map(({ SKU, name, fees }) => (
              <option key={SKU} value={SKU}>
                {name}
                {fees && ` (${formatAmount(visibleFeeSubtotal(fees))})`}
              </option>
            ))}
          </select>
        </div>
      )}
      <button onClick={onHandleClick} disabled={state === ButtonState.Added}>
        <ButtonLabel product={product} state={state} />
      </button>
    </>
  );
}

interface ButtonLabelProps {
  product: Product;
  option?: Option;
  state: ButtonState;
}

// ButtonLabel renders the button label for the supplied product.
function ButtonLabel({ product, option, state }: ButtonLabelProps): JSX.Element {
  if (state === ButtonState.Added) {
    return <>Added...</>;
  }
  if (product.feeLabel) {
    return (
      <span>
        <i className="fas fa-cart-plus"></i>
        {product.feeLabel}
      </span>
    );
  }

  const fees = option?.fees || product.fees;
  return (
    <span>
      <i className="fas fa-cart-plus"></i> {formatAmount(visibleFeeSubtotal(fees))}{" "}
      <span style={{ fontSize: "0.7em", opacity: 0.5 }}>ea.</span>
    </span>
  );
}
