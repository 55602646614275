import * as React from "react";

import {
  ConveyanceType,
  Order,
  orderAllowsShipping,
  orderHasFreeShipping,
  orderIsTimeSensitive,
  orderRemainingForFreeShipping,
  orderShippingCost,
} from "../../models/order";

import Decimal from "decimal.js";
import Deemphasized from "../core/Deemphasized";
import FreeShippingCTA from "./FreeShippingCTA";
import Select from "../core/Select";
import { formatAmount } from "../../util/currency";
import { setConveyanceType } from "../../hooks/Order";

interface SelectConveyanceProps {
  order: Order;
}

// SelectConveyance renders a widget to allow the user to select how the product should
// be acquired, be it shipping, pickup, delivery, etc.
//
// Time sensitive orders are not provided the shipping option. These are reserved for orders that can be delivered
// on a loose time schedule.
export default function SelectConveyance({ order }: SelectConveyanceProps) {
  const deliveryRef = React.useRef<HTMLSelectElement>(null);
  const message = conveyanceMessage(order);

  function onChangeDelivery() {
    if (!deliveryRef.current) {
      return;
    }
    const type = parseInt(deliveryRef.current.value);
    setConveyanceType(type);
  }

  let shippingOption: JSX.Element | undefined;
  if (orderAllowsShipping(order)) {
    const shippingCost = orderShippingCost(order);
    const freeShippingRemaining = orderRemainingForFreeShipping(order);

    let message = `${formatAmount(shippingCost)} (+tax) flat rate`;
    if (shippingCost.isZero() || freeShippingRemaining.lessThanOrEqualTo(new Decimal(0))) {
      message = "Free";
    }

    shippingOption = <option value={ConveyanceType.Ship}>Shipping: {message}</option>;
  }

  const isTimeSensitive = orderIsTimeSensitive(order);
  return (
    <>
      <div>Select shipping option:</div>

      <Select selectRef={deliveryRef} value={order.conveyanceType} onChange={onChangeDelivery}>
        {shippingOption}
        <option value={ConveyanceType.Pickup}>Dine In/Pickup: Free</option>
        {!isTimeSensitive && <option value={ConveyanceType.LocalDelivery}>Local Delivery: Free</option>}
      </Select>

      <Deemphasized>
        <p>{message}</p>
      </Deemphasized>
    </>
  );
}

function conveyanceMessage(order: Order): JSX.Element | undefined {
  switch (order.conveyanceType) {
    case ConveyanceType.Ship:
      return (
        <>
          Shipping orders usually ship within 1 to 3 business days.{" "}
          {!orderHasFreeShipping(order) &&
            `Local to Bayfield? Check out the pickup and local delivery
          options.`}{" "}
          <FreeShippingCTA order={order} />
        </>
      );

    case ConveyanceType.Pickup:
      return <>Pickup order is available for pickup during regular retail hours.</>;

    case ConveyanceType.LocalDelivery:
      return (
        <>Local delivery order is available for free delivery. Outside of the Bayfield area? See the Shipping option.</>
      );

    default:
      return undefined;
  }
}
