import * as React from "react";
import * as classes from "./CartTotal.css";

import { Order, orderSubtotal, orderTax, orderTotal } from "../../models/order";

import Divider from "../core/Divider";
import Padded from "../core/Padded";
import SelectConveyance from "./SelectConveyance";
import { formatAmount } from "../../util/currency";

interface CartTotalProps {
  order: Order;
}

// CartSubtotal renders a subtotal view.
export default function CartTotal({ order }: CartTotalProps) {
  return (
    <Padded>
      <div className={classes.container}>
        Subtotal: <strong>{formatAmount(orderSubtotal(order))}</strong>
      </div>

      <Divider />

      <div className={classes.container}>
        <SelectConveyance order={order} />
      </div>

      <Divider />

      <div className={classes.container}>
        13% HST: <strong>{formatAmount(orderTax(order))}</strong>
      </div>

      <div className={[classes.container, classes.total].join(" ")}>
        Total: <strong>{formatAmount(orderTotal(order))}</strong>
      </div>
    </Padded>
  );
}
