import * as React from "react";
import * as classes from "./BusinessHours.css";

import { DAYS, formatTime } from "../../util/date";

import { Hours } from "../../models/timeslot";

interface BusinessHoursProps {
  hours: Hours[];
}

// BusinessHours renders a table of business hours.
export default function BusinessHours({ hours }: BusinessHoursProps): JSX.Element {
  const days: { [key: number]: string[] } = {};
  for (let i = 0; i < 7; i++) {
    days[i] = [];
  }

  const now = new Date();
  for (const entry of hours) {
    if (entry.validFrom > now || entry.validThrough < now) {
      continue;
    }

    const times = [
      formatTime(new Date(0, 0, 0, entry.open.hour, entry.open.minute)),
      formatTime(new Date(0, 0, 0, entry.close.hour, entry.close.minute)),
    ].join("-");
    days[entry.weekday].push(times);
  }

  const rows: JSX.Element[] = [];
  for (let i = 0; i < 7; i++) {
    rows.push(
      <tr key={i}>
        <th>{DAYS[i]}</th>
        <td>{days[i].length === 0 ? "Closed" : days[i].join(", ")}</td>
      </tr>
    );
  }

  return (
    <table className={classes.base}>
      <tbody>{rows}</tbody>
    </table>
  );
}
