import * as React from "react";

import { OrderItem, itemSubtotal } from "../../models/orderItem";
import { OrderProviderProps, Step } from "./OrderController";

import { Action } from "../../models/action";
import Button from "../../components/core/Button";
import ButtonGroup from "../../components/core/ButtonGroup";
import CartItem from "../../components/orders/CartItem";
import CartTotal from "../../components/orders/CartTotal";
import Divider from "../../components/core/Divider";
import Padded from "../../components/core/Padded";
import Screen from "../../components/core/Screen";
import { orderIsTimeSensitive } from "../../models/order";
import { setItem } from "../../hooks/Order";
import { trackOrderAction } from "../../service/analytics";

interface CartControllerProps extends OrderProviderProps {}

// CartController provides cart management functionality.
export default function CartController({ order, onNavigate }: CartControllerProps) {
  function onRemove(item: OrderItem) {
    setItem(item.product, item.option, 0);

    trackOrderAction(Action.remove_from_cart, {
      items: [
        {
          id: item.product.SKU,
          name: item.product.name,
          price: itemSubtotal(item).toDecimalPlaces(2).toString(),
        },
      ],
    });
  }

  function onBack() {
    onNavigate(Step.Shop);
  }

  const hasItems = !!order.items.length;
  const isTimeSensitive = orderIsTimeSensitive(order);
  function onNext() {
    if (!hasItems) {
      return;
    }

    if (isTimeSensitive) {
      onNavigate(Step.FindTime);
      return;
    }

    onNavigate(Step.Pay);
  }

  return (
    <Screen>
      <Padded>
        <h1>Your Order</h1>
        <p>
          At this time we can only ship to customers within the province of Ontario. Free shipping for local delivery
          and orders over $80. Must be 19+ years of age. Please enjoy responsibly.
        </p>
      </Padded>

      <div>
        {order.items.map((item) => (
          <CartItem item={item} onChange={setItem} onRemove={onRemove} />
        ))}

        <Divider />

        <CartTotal order={order} />
      </div>

      <ButtonGroup>
        <Button onClick={onNext} wide actionable>
          Checkout
        </Button>
        <Button onClick={onBack} wide>
          Continue shopping
        </Button>
      </ButtonGroup>
    </Screen>
  );
}
