import * as React from "react";

import { OrderProviderProps, Step } from "./OrderController";

import BusinessHoursController from "../app/BusinessHoursController";
import Button from "../../components/core/Button";
import ButtonGroup from "../../components/core/ButtonGroup";
import DateTimePicker from "../../components/app/DateTimePicker";
import Loading from "../../components/app/Loading";
import Padded from "../../components/core/Padded";
import Screen from "../../components/core/Screen";
import { orderLeadTime, orderProductsAvailability, orderTimeslot } from "../../models/order";
import { requestTimeslotOffset } from "../../service/timetable";
import { roundInterval } from "../../util/time";
import { setFulfillmentEvent } from "../../hooks/Order";

interface FindTimeControllerProps extends OrderProviderProps {}

export default function FindTimeController({ order, onNavigate }: FindTimeControllerProps): JSX.Element {
  const timeslot = orderTimeslot(order);
  const [selectedTime, setSelectedTime] = React.useState(roundInterval(timeslot));
  const [loading, setLoading] = React.useState(false);

  function onBack() {
    onNavigate(Step.Cart);
  }

  // onNext is called when the user has indicated they wish to move to the next step.
  async function onNext() {
    try {
      setLoading(true);

      const timeslot = await requestTimeslotOffset(
        selectedTime,
        orderLeadTime(order),
        orderProductsAvailability(order),
        order.timeslot?.id
      );
      setFulfillmentEvent(timeslot);

      if (timeslot.reason === "available") {
        onNavigate(Step.Pay);
      } else if (timeslot.reason === "failed") {
        onNavigate(Step.FindTime);
      } else {
        onNavigate(Step.ConfirmTime);
      }
    } catch (err) {
      // TODO: Handle error.
      console.error(err);
      throw err;
    } finally {
      setLoading(false);
    }
  }

  let body = <Loading>Looking for available times...</Loading>;
  if (!loading) {
    body = (
      <Padded>
        <DateTimePicker date={selectedTime} onChange={setSelectedTime} />
        <BusinessHoursController />
      </Padded>
    );
  }
  return (
    <Screen>
      <Padded>
        <h1>Find a Time</h1>
        <p>Please let us know when you would prefer to have you order ready.</p>
      </Padded>

      {body}

      <ButtonGroup>
        <Button onClick={onNext} wide actionable disabled={loading}>
          Next
        </Button>
        <Button onClick={onBack} wide>
          Back
        </Button>
      </ButtonGroup>
    </Screen>
  );
}
