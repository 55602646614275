import * as React from "react";

import { OrderProviderProps, Step } from "./OrderController";
import { orderIsTimeSensitive, orderShippingTotal, orderTax } from "../../models/order";

import { Action } from "../../models/action";
import Pay from "../../components/orders/Pay";
import { PaymentInfo } from "../../models/payment";
import { StripeCardElement } from "@stripe/stripe-js";
import { commitTimeslot } from "../../service/timetable";
import { getErrorMessage } from "../../util/errors";
import { itemSubtotal } from "../../models/orderItem";
import { placeOrder } from "../../service/order";
import { setOrderID } from "../../hooks/Order";
import { trackOrderAction } from "../../service/analytics";
import { useStripe } from "@stripe/react-stripe-js";

interface PaymentControllerProps extends OrderProviderProps {}

// PaymentController provides payment functionality.
export default function PaymentController({ order, onNavigate }: PaymentControllerProps) {
  const stripe = useStripe();
  const [error, setError] = React.useState<string | string[] | undefined>(undefined);

  function onBack() {
    if (orderIsTimeSensitive(order)) {
      onNavigate(Step.FindTime);
    } else {
      onNavigate(Step.Cart);
    }
  }

  async function onSubmit(paymentInfo: PaymentInfo, cardElement: StripeCardElement, onFinished: () => void) {
    function setErrorFinished(err: Error | string | string[]) {
      const message = getErrorMessage(err);
      setError(message);
      onFinished();
    }

    try {
      if (!stripe) {
        throw new Error("Payment processor not configured");
      }

      const { error, token } = await stripe.createToken(cardElement, {
        name: `${paymentInfo.first_name} ${paymentInfo.last_name}`,
        address_line1: paymentInfo.shipping_street_1,
        address_line2: paymentInfo.shipping_street_2,
        address_city: paymentInfo.shipping_city,
        address_state: "Ontario",
        address_zip: paymentInfo.shipping_postal_code,
        address_country: "CA",
      });
      if (error) {
        throw error;
      }
      if (!token) {
        throw new Error("Payment token not found");
      }

      // If the order has an associated timetable event we need to commit to the reservation.
      if (order.timeslot) {
        await commitTimeslot(order.timeslot);
      }

      const { id } = await placeOrder(order, paymentInfo, token);
      if (!id || id === "") {
        throw new Error("Unable to place order. Please try again later.");
      }
      setOrderID(id);
      onNavigate(Step.Finish);

      trackOrderAction(Action.purchase, {
        shipping: orderShippingTotal(order).toDecimalPlaces(2).toString(),
        tax: orderTax(order).toDecimalPlaces(2).toString(),
        items: order.items.map((item) => ({
          id: item.product.SKU,
          name: item.product.name,
          price: itemSubtotal(item).toDecimalPlaces(2).toString(),
          quantity: item.quantity,
        })),
      });
    } catch (err) {
      setErrorFinished(getErrorMessage(err));
    }
  }

  return <Pay order={order} error={error} onBack={onBack} onSubmit={onSubmit} />;
}
