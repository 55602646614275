import * as React from "react";
import * as classes from "./DateTimePicker.css";

import Calendar from "./Calendar";
import Padded from "../core/Padded";
import Select from "../core/Select";
import Split from "../core/Split";
import { formatTime } from "../../util/date";

interface DateTimePickerProps {
  date: Date;
  onChange(date: Date): void;
}

// DateTimePicker renders a widget to allow the user to select a date and time.
export default function DateTimePicker({ date, onChange }: DateTimePickerProps): JSX.Element {
  // onUpdateCalendar is called when the calendar date is changed. The assigned date is merged with the selected time.
  function onUpdateCalendar(calendarDate: Date) {
    onChange(
      new Date(
        calendarDate.getFullYear(),
        calendarDate.getMonth(),
        calendarDate.getDate(),
        date.getHours(),
        date.getMinutes()
      )
    );
  }

  // onUpdateTime is called when a new time is selected. The assignment is merged with the selected date.
  function onUpdateTime(event: React.ChangeEvent<HTMLSelectElement>) {
    const [hours, minutes] = event.target.value.split(":", 2);
    onChange(new Date(date.getFullYear(), date.getMonth(), date.getDate(), parseInt(hours), parseInt(minutes)));
  }

  const defaultValue = `${date.getHours()}:${date.getMinutes()}`;
  return (
    <Padded>
      <Split>
        <Calendar date={date} onChange={onUpdateCalendar} />
        <div className={classes.selectTime}>
          <p>Select your preferred time</p>
          <div>
            <Select value={defaultValue} onChange={onUpdateTime}>
              {timeOptions}
            </Select>
          </div>
        </div>
      </Split>
    </Padded>
  );
}

// generateTimeOptions prepares time selection options.
function generateTimeOptions(): JSX.Element[] {
  const timeOptions = [];
  for (let i = 0; i < 24; i++) {
    let hour = i;
    let meridiem = "AM";
    if (hour === 0) {
      hour = 12;
    } else if (hour > 12) {
      hour -= 12;
      meridiem = "PM";
    }

    for (let minute = 0; minute < 60; minute += 15) {
      const time = formatTime(new Date(0, 0, 0, i, minute));
      timeOptions.push(
        <option key={time} value={`${i}:${minute}`}>
          {time}
        </option>
      );
    }
  }
  return timeOptions;
}

// timeOptions is a static list of time options that are cached at startup.
const timeOptions = generateTimeOptions();
