// A FormElement identifies an element that conforms
// the the form name/value interface.
interface FormElement {
  name?: string;
  value?: string;
}

// readForm returns an object containing keys and values that match the form
// input fields that are contained within the supplied form element.
export function readForm(form: HTMLFormElement): { [key: string]: any } {
  const data: { [key: string]: string | undefined } = {};
  for (const element of Array.from(form.elements)) {
    const formElement = element as FormElement;
    if (formElement.name) {
      data[formElement.name] = formElement.value;
    }
  }
  return data;
}
