import * as React from "react";
import MaskedInput, { MaskedInputProps } from "react-text-mask";
import * as classes from "./Input.css";

interface InputProps extends React.HTMLProps<HTMLInputElement> {
  onValueChange?(value: string): void;
}

interface InputMaskedProps extends MaskedInputProps {
  mask: MaskedInputProps["mask"];
  onValueChange?(value: string): void;
}

// Input renders a text input control.
export default function Input(props: InputProps | InputMaskedProps) {
  const { onChange, onValueChange, className } = props;

  function onHandleValueChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (onChange) {
      onChange(event);
    }
    if (onValueChange) {
      onValueChange(event.currentTarget.value);
    }
  }

  const classNameSet = [classes.input, className].join(" ");

  if ("mask" in props) {
    return <MaskedInput className={classNameSet} onChange={onHandleValueChange} {...props} />;
  }
  return <input className={classNameSet} onChange={onHandleValueChange} {...props} />;
}
