import * as React from "react";

import BusinessHours from "../../components/app/BusinessHours";
import { Hours } from "../../models/timeslot";
import { loadBusinessHours } from "../../service/timetable";

interface BusinessHoursControllerProps {}

// BusinessHoursController fetches business hours data and renders the result.
export default function BusinessHoursController(props: BusinessHoursControllerProps): JSX.Element {
  const [hours, setHours] = React.useState<Hours[] | null>(null);

  React.useEffect(() => {
    async function load() {
      const hours = await loadBusinessHours();
      setHours(hours);
    }
    load();
  }, []);

  if (!hours) {
    return <></>;
  }
  return <BusinessHours hours={hours} />;
}
