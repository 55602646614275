// Action identifies types of user interaction.
export enum Action {
  // Custom actions.
  // TODO: Add custom actions.

  // Suggested actions from gtag.js.
  add_payment_info = "add_payment_info",
  add_to_cart = "add_to_cart",
  add_to_wishlist = "add_to_wishlist",
  begin_checkout = "begin_checkout",
  checkout_progress = "checkout_progress",
  exception = "exception",
  generate_lead = "generate_lead",
  login = "login",
  page_view = "page_view",
  purchase = "purchase",
  refund = "refund",
  remove_from_cart = "remove_from_cart",
  screen_view = "screen_view",
  search = "search",
  select_content = "select_content",
  set_checkout_option = "set_checkout_option",
  share = "share",
  sign_up = "sign_up",
  timing_complete = "timing_complete",
  view_item = "view_item",
  view_item_list = "view_item_list",
  view_promotion = "view_promotion",
  view_search_results = "view_search_results",
}

export type EventParams = Gtag.EventParams;
