import * as React from "react";
import * as classes from "./Screen.css";

interface ContainerProps {}

// Screen lays out the childen in the desired screen composition.
export default function Screen({
  children
}: React.PropsWithChildren<ContainerProps>) {
  return <div className={classes.container}>{children}</div>;
}
